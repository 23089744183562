import React, { createContext, useEffect, useReducer } from 'react';
import { actions, updateOntologyInformation, updateArchitecturalTypeColors, updateDefaultArchitecturalTypeColors } from './actions';

import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';

import { ObjectTypes } from "../Description/CreateDescription/DescriptionObjects/types";

import Cryptic from "../../assets/cryptic.svg"
import Erosive from "../..//assets/erosive.svg"
import Faulted from "../../assets/faulted.svg"
import Gradational from "../../assets/gradational.svg"
import LagDeposit from "../../assets/lagDeposit.svg"
import Sharp from "../../assets/sharp.svg"
import Undefined from "../../assets/undefined.png"
import Icon from '@ant-design/icons';
import { useAxiosGet } from '../../hooks/useAxios';


const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;

const initialState = {
    descriptionInformation: {
        descriptionType: "",
        topDepth: "",
        bottomDepth: "",
        unit: "",
        coordinatesType: "dms",
        latitude: null,
        longitude: null,
        country: "",
        state: "",
        city: "",
        author: "",
        dataDate: "",
        docSrc: [],
        refLink: [],
        geologicalSiteName: "",
        watershed: "",
        fieldName: "",
        lithostratigraphyGroup: "",
        lithostratigraphyFormation: "",
        lithostratigraphyMember: "",
        geochronology: [],
        sedimentaryEnvironment: [],
        depositionalSystem: [],
        additionalInformation: "",
        descriptionInformationFeedback: ""
    },
    descriptionObjects: {
        selectedObject: [],
        nextId: {},
        objects: [],
        box: { update: 0 },
        pit: {},
        descriptionObjectsFeedback: "",
        file: null,
        updatedObjects: 0,
        segment: {
            selectingSegment: null,
            checkedsObjectsSegments: [],
            tree: [],
            nextId: 0,
            selectedSegment: [],
            updated: 0,
        },
        contacts: {
            tree: [],
            nextId: 0,
            selectedContact: [],
            updated: 0,
        },
        polygonProperties: {},
    },
    descriptionDataRetrieval: {},
    descriptionCharts: { "component": [], "key": [], "option": [] },
    isEditing: false,
    architecturalTypeColors: {},
    defaultArchitecturalTypeColors: {},
    ontologyInformation: {
        architecturalElementTypes: {},
        faciesAssociationTypes: {},
    }
}

const store = createContext(initialState);
const { Provider } = store;

const descriptionReducer = (state, action) => {
    switch (action.type) {
        case actions.SET_EDITING_DESCRIPTION:
            console.log("actions.SET_EDITING_DESCRIPTION", action.value);
            return { ...state, isEditing: action.value };

        case actions.SET_DESCRIPTION_INFORMATION:
            console.log("actions.SET_DESCRIPTION_INFORMATION", action.value);
            return { ...state, descriptionInformation: action.value };

        case actions.SET_DESCRIPTION_OBJECTS:
            // console.log("actions.SET_DESCRIPTION_OBJECTS", action.value);
            return { ...state, descriptionObjects: action.value };

        case actions.ADD_DESCRIPTION_OBJECT:
            console.log("ADD_DESCRIPTION_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    nextId: action.value.nextId,
                    objects: action.value.objects
                }
            }

        case actions.UPDATE_OBJECT_STRUCTURE:
        case actions.SET_OBJECT_PROPERTIES:
            console.log("UPDATE_OBJECT_STRUCTURE || SET_OBJECT_PROPERTIES", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    objects: action.value
                }
            }

        case actions.SET_SELECTED_OBJECT:
            console.log("SET_SELECTED_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: action.value,
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectedSegment: [],
                    },
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: []
                    }
                }
            }

        case actions.SET_SELECTED_OBJECT_PROPERTIES:
            console.log("SET_SELECTED_OBJECT_PROPERTIES", action.value);;
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: {
                        ...state.descriptionObjects.selectedObject,
                        properties: {
                            ...state.descriptionObjects.selectedObject.properties,
                            width: action.value.width,
                            thickness: action.value.thickness,
                        }
                    }
                }
            }

        case actions.DELETE_DESCRIPTION_OBJECT:
            console.log("DELETE_DESCRIPTION_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    objects: action.value.objects,
                    selectedObject: action.value.selectedObject,
                    deletedObject: action.value.deletedObject
                }
            }

        case actions.UPDATE_DESCRIPTION_OBJECTS_FEEDBACK:
            console.log("UPDATE_DESCRIPTION_OBJECTS_FEEDBACK", action.value);;
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    descriptionObjectsFeedback: action.value,
                }
            }

        case actions.SELECTING_SEGMENT:
            console.log("SELECTING_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectingSegment: action.value,
                    }
                }
            }

        case actions.CHECKED_OBJECTS_SEGMENT:
            console.log("CHECKED_OBJECTS_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    segment: {
                        ...state.descriptionObjects.segment,
                        checkedsObjectsSegments: action.value,
                    }
                }
            }

        case actions.UPDATE_SEGMENT:
            console.log("UPDATE_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    segment: {
                        ...state.descriptionObjects.segment,
                        tree: action.value.tree,
                        nextId: action.value.nextId,
                        //id: action.value.nextId,
                    },
                }
            }

        case actions.UPDATE_CONTACTS:
            console.log("UPDATE_CONTACT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        tree: action.value.tree,
                        nextId: action.value.nextId,
                        //id: action.value.nextId,
                    },
                }
            }

        case actions.SET_SELECTED_SEGMENT:
            console.log("SET_SELECTED_SEGMENT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: [],
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectedSegment: action.value,
                    },
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: []
                    }
                }
            }

        case actions.SET_SELECTED_CONTACT:
            console.log("SET_SELECTED_CONTACT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: [],
                    segment: {
                        ...state.descriptionObjects.segment,
                        selectedSegment: [],
                    },
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: action.value,
                    },
                }
            }

        case actions.SET_DESCRIPTION_DATA_RETRIEVAL:
            console.log(action.value);
            return {
                ...state,
                descriptionDataRetrieval: action.value
            }

        case actions.SET_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: action.value
            }

        case actions.DELETE_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: deleteDescriptionChartByIndex({ ...state.descriptionCharts }, action.value)
            }

        case actions.UPDATE_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: {
                    key: [...state.descriptionCharts.key, action.value.key],
                    option: [...state.descriptionCharts.option, action.value.option],
                    component: [...state.descriptionCharts.component, action.value.component]
                }
            }

        case actions.SET_FILE:
            console.log("SET_FILE", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    file: action.value
                }
            }

        case actions.UPDATE_ARCHITECTURAL_TYPE_COLORS:
            console.log(action.value);
            return {
                ...state,
                architecturalTypeColors: action.value
            }
        
        case actions.UPDATE_DEFAULT_ARCHITECTURAL_TYPE_COLORS:
            console.log(action.value);
            return {
                ...state,
                defaultArchitecturalTypeColors: action.value
            }

        case actions.UPDATE_ONTOLOGY_INFORMATION:
            console.log(action.value);
            return{
                ...state,
                ontologyInformation: action.value
            }

        default:
            return state;
    };
}

const deleteDescriptionChartByIndex = (data, indexToDelete) => {
    return {
        key: data.key.filter((_, index) => index !== indexToDelete),
        option: data.option.filter((_, index) => index !== indexToDelete),
        component: data.component.filter((_, index) => index !== indexToDelete)
    };
}

// Função para gerar uma cor baseada em HSL
const generateColor = (index, total) => {
    const hue = (index / total) * 360; // Distribui os tons uniformemente ao longo da roda de cores (HSL)
    return `hsl(${hue}, 70%, 50%)`; // Saturação e luminosidade fixas para manter contraste
};

// Função para gerar uma paleta de cores baseada no número de classes
const generatePalette = (classes) => {
    const totalClasses = classes.length;
    const preferredLanguages = ["pt-br", "pt"];

    const colorMap = {};

    if (Object.keys(classes).length !== 0) {
        classes.forEach((x, index) => {
            colorMap[x.labels[preferredLanguages.find(lang => x.labels[lang])]] = generateColor(index, totalClasses); // Atribui uma cor para cada classe
        });
    }


    return colorMap;

};

const StateProvider = ({ children }) => {
    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const { data: architecturalElementData, error: architecturalElementDataError, loaded: architecturalElementDataLoaded } = useAxiosGet(descriptionInfoURL, sessionStorage);
    const urlGeologicalObjects = 'geologicalObjects';
    const urlArchitecturalElement = 'architecturalElement';
    const urlArchitecturalElementType = 'architecturalElementType';
    const urlFaciesAssociation = "faciesAssociation";
    const urlTypeFaciesAssociation = "typeFaciesAssociation";

    function capitalizeFirstLetter(string) {
        return string[0].charAt(0).toUpperCase() + string[0].slice(1);
    }

    function processOntologyList(object) {
        //Sorting by portuguese label
        object.sort((a, b) => {
            const labelA = a.labels["pt-br"] || a.labels["pt"];
            const labelB = b.labels["pt-br"] || b.labels["pt"];

            if (labelA < labelB) return -1;
            if (labelA > labelB) return 1;
            return 0;
        });

        //Convert the first letter to uppercase
        object.forEach(obj => {
            if (obj.labels["pt-br"]) {
                obj.labels["pt-br"] = capitalizeFirstLetter(obj.labels["pt-br"]);
            }

            if (obj.labels["pt"]) {
                obj.labels["pt"] = capitalizeFirstLetter(obj.labels["pt"]);
            }
        });

        return object;
    }

    useEffect(() => {
        if (architecturalElementData != null) {
            let faciesAssociationTypes = architecturalElementData[urlGeologicalObjects][urlFaciesAssociation][urlTypeFaciesAssociation].types;
            let architecturalElementTypes = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlArchitecturalElementType].types;

            dispatch(updateOntologyInformation({
                faciesAssociationTypes: processOntologyList(faciesAssociationTypes),
                architecturalElementTypes: processOntologyList(architecturalElementTypes),
            }))

            const palette = {...generatePalette(architecturalElementTypes), "Não definido": "hsl(0, 0%, 0%)"};
            // Do not overwrite architecturalTypeColors if already in state
            if (Object.keys(state.architecturalTypeColors).length === 0) {
                dispatch(updateArchitecturalTypeColors(palette));
            }

            dispatch(updateDefaultArchitecturalTypeColors(palette));
        }
    }, [architecturalElementData]);

    const getIconObjects = (type) => {
        switch (type) {
            case UNIT:
                return <tbIcons.TbChartRadar />;
            case FACIES_ASSOCIATION:
                return <FaIcons.FaRegCircle />;
            case ARCH_ELEMENT:
                return <tbIcons.TbChartBubble />;
            default:
                return;
        }
    }

    const getIconSegments = (type) => {
        switch (type) {
            case "Abrupto":
                return Sharp;
            case "Críptico":
                return Cryptic;
            case "De falha":
                return Faulted;
            case "Erosional":
                return Erosive;
            case "Gradacional":
                return Gradational;
            case "Linha de seixos":
                return LagDeposit;
            case "Indefinido":
                return Undefined;
            default:
                return;
        }
    }

    function getIconContacts(type) {
        const icons = {
            'Abrupto': <img src={Sharp} alt="Sharp" style={{ width: '20px', height: '20px' }} />,
            'Críptico': <img src={Cryptic} alt="Cryptic" style={{ width: '20px', height: '20px' }} />,
            'De falha': <img src={Faulted} alt="Faulted" style={{ width: '20px', height: '20px' }} />,
            'Erosional': <img src={Erosive} alt="Erosive" style={{ width: '20px', height: '20px' }} />,
            'Gradacional': <img src={Gradational} alt="Gradational" style={{ width: '20px', height: '20px' }} />,
            'Linha de seixos': <img src={LagDeposit} alt="Lag Deposit" style={{ width: '20px', height: '20px' }} />,
            'Indefinido': <img src={Undefined} alt="Undefined" style={{ width: '20px', height: '20px' }} />,
        };

        return icons[type] || null;
    }

    const addIconToObj = (node, index, array) => {
        let new_node = node;

        new_node.icon = getIconObjects(node.geologicalObjectType)

        if (node.hasOwnProperty('children'))
            new_node.children = node.children.map(addIconToObj);

        return new_node;
    }

    const addIconToSegment = (node, index, array) => {
        let new_node = node;
        new_node.icon = <Icon component={() => (<img src={getIconSegments(node.typeSegment)} style={{ padding: 1, width: 40, marginLeft: -13 }} />)} />
        return new_node;
    }

    const addIconToContact = (node, index, array) => {
        let new_node = node;
        new_node.icon = <Icon component={() => getIconContacts(node.contactType)} />;
        return new_node;
    }

    const [state, dispatch] = useReducer(descriptionReducer, {}, () => {
        const localDescriptionInfo = localStorage.getItem('descriptionInformation');
        const localDescriptionObjects = localStorage.getItem('descriptionObjects');
        const localDescriptionDataRetrieval = localStorage.getItem('descriptionDataRetrieval');
        const localDescriptionCharts = localStorage.getItem('descriptionCharts');
        const localArchitecturalTypeColors = localStorage.getItem('architecturalTypeColors');
        const localDefaultArchitecturalTypeColors = localStorage.getItem('defaultArchitecturalTypeColors');
        const localOntologyInformation = localStorage.getItem('ontologyInformation');

        if (localDescriptionInfo)
            if (localDescriptionObjects) {
                const aux_descriptionObjects = JSON.parse(localDescriptionObjects);
                const objectsWithIcon = aux_descriptionObjects.objects.map(addIconToObj);
                const segmentWithIcon = aux_descriptionObjects.segment.tree.map(addIconToSegment);
                const ContactWithIcon = aux_descriptionObjects.contacts.tree.map(addIconToContact);

                //const parsedArchitecturalTypeColors = localArchitecturalTypeColors ? JSON.parse(localArchitecturalTypeColors) : initialState.architecturalTypeColors;
                const infos = {
                    descriptionInformation: JSON.parse(localDescriptionInfo),
                    descriptionObjects: {
                        //selectedObject: aux_descriptionObjects.selectedObject,
                        //nextId: aux_descriptionObjects.nextId,
                        //segment: aux_descriptionObjects.segment,
                        ...aux_descriptionObjects,
                        box: { update: 0 },
                        objects: objectsWithIcon,
                        //descriptionObjectsFeedback: aux_descriptionObjects.descriptionObjectsFeedback,
                        segment: {
                            ...aux_descriptionObjects.segment,
                            tree: segmentWithIcon,
                        },
                        contacts: {
                            ...aux_descriptionObjects.contacts,
                            tree: ContactWithIcon,
                        }
                    },
                    descriptionDataRetrieval: JSON.parse(localDescriptionDataRetrieval),
                    descriptionCharts: JSON.parse(localDescriptionCharts),
                    ontologyInformation: JSON.parse(localOntologyInformation),
                    architecturalTypeColors: JSON.parse(localArchitecturalTypeColors),
                    defaultArchitecturalTypeColors: JSON.parse(localDefaultArchitecturalTypeColors)
                };
                return infos;
            }
            else {
                return initialState
                //return { descriptionInformation: JSON.parse(localDescriptionInfo), descriptionObjects: initialState.descriptionObjects, descriptionDataRetrieval: initialState.descriptionDataRetrieval,  descriptionCharts: initialState.descriptionCharts}
                //return { descriptionInformation: JSON.parse(localDescriptionInfo), descriptionObjects: initialState.descriptionObjects}
            }
        else {
            return initialState;
        }

    });

    useEffect(() => {
        localStorage.setItem('descriptionInformation', JSON.stringify(state.descriptionInformation));
    }, [state.descriptionInformation]);


    useEffect(() => {
        const { file, ...descriptionObjects } = state.descriptionObjects;
        const objectsWithIcon = descriptionObjects.objects.map(addIconToObj);
        const segmentWithIcon = descriptionObjects.segment.tree.map(addIconToSegment);
        const ContactWithIcon = descriptionObjects.contacts.tree.map(addIconToContact);

        const descriptionObjectsParsed = {
            //selectedObject: state.descriptionObjects.selectedObject,
            //nextId: state.descriptionObjects.nextId,
            //segment: state.descriptionObjects.segment,
            ...descriptionObjects,
            objects: objectsWithIcon,
            //descriptionObjectsFeedback: state.descriptionObjects.descriptionObjectsFeedback
            segment: {
                ...descriptionObjects.segment,
                tree: segmentWithIcon,
            },
            contacts: {
                ...state.descriptionObjects.contacts,
                tree: ContactWithIcon,
            }
        }

        if (file)
            descriptionObjectsParsed.file = file.name;
        else
            descriptionObjectsParsed.file = null;

        localStorage.setItem('descriptionObjects', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionObjects]);

    useEffect(() => {
        const descriptionObjectsParsed = { ...state.descriptionDataRetrieval }


        localStorage.setItem('descriptionDataRetrieval', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionDataRetrieval]);

    useEffect(() => {
        const descriptionObjectsParsed = { ...state.descriptionCharts };

        localStorage.setItem('descriptionCharts', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionCharts]);

    useEffect(() => {
        const architecturalTypeColorsPased = { ...state.architecturalTypeColors };

        localStorage.setItem('architecturalTypeColors', JSON.stringify(architecturalTypeColorsPased));
    }, [state.architecturalTypeColors]);

    useEffect(() => {
        const defaultArchitecturalTypeColorsPased = { ...state.defaultArchitecturalTypeColors };

        localStorage.setItem('defaultArchitecturalTypeColors', JSON.stringify(defaultArchitecturalTypeColorsPased));
    }, [state.defaultArchitecturalTypeColors]);

    useEffect(() => {
        const ontologyInformation = { ...state.ontologyInformation };

        localStorage.setItem('ontologyInformation', JSON.stringify(ontologyInformation));
    }, [state.ontologyInformation]);

    return <Provider value={{ state, dispatch, architecturalElementDataLoaded }}>{children}</Provider>;
};

export { store, StateProvider, initialState }

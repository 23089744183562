import React, { useState, useContext } from 'react';
import './App.css';
import Home from '../Home';
import Sidebar from '../Sidebar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Description from '../Description';
import Login from '../Login';
import CreateDescription from '../Description/CreateDescription/index';
import CreateOccurrence from '../Occurrence/CreateOccurrence/index';
import DescriptionObjects from '../Description/CreateDescription/DescriptionObjects/index';
import ReadOccurrence from '../Occurrence/ReadOccurrence/index';
import ReadDescription from '../Description/ReadDescription/index';
import Notifications from '../Notifications';
import Drafts from '../Description/Drafts/index';
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { store } from '../Store';

function App() {
  const globalState = useContext(store);
  const { architecturalElementDataLoaded } = globalState;

  const getToken = () => {
    const tokenString = localStorage.getItem('user');
    const userToken = JSON.parse(tokenString);
    return userToken?.access_token;
  };

  const [token, setToken] = useState(getToken());

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <>
      <div class="wrapper">
        <Router>
          <Sidebar />
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/description' element={<Description/>} />
            <Route path='/cdescription' element={<CreateDescription/>} />
            <Route path='/rdescription/:descriptionId' element={<ReadDescription/>} />
            <Route path='/coccurrence' element={<CreateOccurrence/>} />
            <Route path='/roccurrence' element={<ReadOccurrence/>} />
            <Route path='/notifications' element={<Notifications/>} />
            <Route path='/drafts' element={<Drafts/>} />
            <Route path='/objdescription' element={<DescriptionObjects/>} />
          </Routes>
        </Router>
      </div>
      <Spin
          spinning={!architecturalElementDataLoaded}
          fullscreen
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      />
    </>
  );
}

export default App;
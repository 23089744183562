import { React, useContext, useEffect, useState } from 'react';
import { store } from '../Store';
import { FormatPainterOutlined } from '@ant-design/icons';
import { updateArchitecturalTypeColors } from "../Store/actions";
import { Button, Drawer, Collapse, Col, Row, ColorPicker } from 'antd';
import Swal from 'sweetalert2';

function ArchitecturalTypeColorsDrawer() {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const architecturalTypeColors = state.architecturalTypeColors;
    const [openFilters, setOpenFilters] = useState(false);

    const handleColorChange = (type, newColor) => {
        dispatch(updateArchitecturalTypeColors({ ...architecturalTypeColors, [type]: newColor.toHexString() }));
    };

    let colorsIcon = <FormatPainterOutlined style={{ display: 'inline-flex' }} />;

    const resetColors = () => {
        Swal.fire({
            title: 'Tem certeza de que deseja redefinir todas as cores para os padrões?',
            text: "Esta ação substituirá as personalizações atuais.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, redefina!',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed)
                dispatch(updateArchitecturalTypeColors(state.defaultArchitecturalTypeColors));
        })
    }
    useEffect(() => {

    }, [state.defaultArchitecturalTypeColors])

    return (
        <>
            <div style={{ position: 'fixed', top: `calc(10% + 50px)`, right: 20 }}>
                <Button size={"large"} onClick={() => setOpenFilters(true)}>{colorsIcon}</Button>
            </div>
            <Drawer
                title={"Personalização"}
                placement="right"
                width={320}
                onClose={() => setOpenFilters(false)}
                open={openFilters}
                style={{ zIndex: '1002' }}
            >
                <Button type="primary" style={{width:'100%', marginBottom:10}} onClick={resetColors}>Redefinir cores</Button>
                <Col style={{ fontSize: 'medium' , paddingBottom: 10}}>
                    Cores dos Tipos Arquiteturais
                </Col>
                <Collapse style={{display: 'flex', gap: 10, justifyContent: "space-between", flexDirection: "column", display: 'flex', alignItems: 'flex-start', padding: 10}}>
                    {Object.keys(architecturalTypeColors).map(type => (
                        <ColorPicker
                            value={architecturalTypeColors[type]}
                            onChangeComplete={(color) => handleColorChange(type, color)}
                            showText={(color) => <span>{type}</span>}
                            format={"rgb"}
                        />
                    ))}
                </Collapse>
                <br />
            </Drawer>
        </>
    );
}

export default ArchitecturalTypeColorsDrawer;

export const ObjectTypes = {
    UNIT: 'Unidade',
    ARCH_ELEMENT: 'ElemArquitetural',
    FACIES_ASSOCIATION: 'AssocFacies'
}

export const ScaleTypes = {
    COMPLEX: 'Complexo',
    COMPOUND: 'Composto',
    INDIVIDUAL: 'Indivíduo',
}

export const ArchitecturalElementTypes = {
    CHANNEL: 'Canal',
    LOBE_SPRAWL: 'Lobo/Espraiamento',
    EXTRAVASATION: 'Extravasamento',
    MASS_TRANSPORT: 'Transporte de massa',
    CONDENSED_SECTION: 'Seção condensada',
    UNDEFINED: 'Não definido',
}

export const ContactTypes = {
    ABRUPT: 'Abrupto',
    CRYPTIC: 'Críptico',
    FAULT: 'De falha',
    EROSIONAL: 'Erosional',
    GRADATIONAL: 'Gradacional',
    UNDEFINED: 'Indefinido',
    PEBBLE_LINE: 'Linha de seixos',
    AMALGAMATED: 'Amalgamado',
    CONCORDANT: 'Concordante'
}

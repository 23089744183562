import { useState, useEffect, useContext, useRef } from 'react';
import "./index.css";
import { EyeOutlined, EyeInvisibleOutlined, InboxOutlined, RedoOutlined, LoadingOutlined, LinkOutlined, SelectOutlined, AreaChartOutlined, LockOutlined, BarChartOutlined } from '@ant-design/icons';
import { Space, message, Upload, Spin, Button, Row, Col, Radio, Menu, Divider, Tooltip, Dropdown, Slider, Modal } from 'antd';
import svgPanZoom from 'svg-pan-zoom'
import { store, initialState } from '../../../../Store';
import { setObjectProperties, setFile, setSelectedObjectProperties, updateSegments, addDescriptionObject, setDescriptionObjectsAction, setSelectedSegment as importedSetSelectedSegment } from '../../../../Store/actions';
import { HighlightSVG } from "../highlightSVG"
import Window from "../../../../Window"
import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';
import { setSelectedObject } from '../../../../Store/actions';
import { getFileFromCache, updateSelectedKeys } from '../../../../../utils';
import axios from "axios";
import { ObjectTypes } from "../types";
import { PitChart } from '../Properties/Charts';
// import Slider from "../../../../Slider";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { initIndexedDb, getItem, STORE_NAME, STORE_KEY } from '../../../../../indexedDBUtils';
import { debounce } from 'lodash';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Dragger } = Upload;

function Hull(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { viewOnly, refresh } = props;
    const [selectedFile, setSelectedFile] = useState();
    const [selectedPolygon, setSelectedPolygon] = useState({ id: "", ctrlKey: false });
    const [preview, setPreview] = useState();
    const [layers, setLayers] = useState([]);
    const [selectedObjectState, setSelectedObjectState] = useState(state.descriptionObjects.selectedObject)
    const [selectedSegment, setSelectedSegment] = useState(state.descriptionObjects.segment.selectedSegment)
    const [selectedContact, setSelectedContact] = useState(state.descriptionObjects.contacts.selectedContact)
    const [descriptionObjects, setDescriptionObjects] = useState(state.descriptionObjects.objects)
    const [segmentData, setSegmentsData] = useState(state.descriptionObjects.segment);
    const [visible, setVisible] = useState({
        "Legenda": true,
        "Escala": true,
        "Interunidade": true,
        "Complexo": true,
        "Composto": true,
        "Individuo": true,
        "Associação de Fácies": true,
    })
    const [scaleDomain, setScaleDomain] = useState({});
    const [scaleRange, setScaleRange] = useState({});
    const [polygonBBox, setPolygonBBox] = useState({});
    const [loading, setLoading] = useState(false);
    const [svgPan, setSvgPan] = useState();
    const [ctm, setCTM] = useState({ a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 });
    const [mode, setMode] = useState(0);
    const [value, setValue] = useState(0);
    const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
    const [fileList, setFileList] = useState([]);
    const [db, setDb] = useState(null);
    const ref = useRef();

    useEffect(() => {
        async function initializeDb() {
            try {
                const db = await initIndexedDb('my-db', [{ name: STORE_NAME, keyPath: STORE_KEY }]);
                setDb(db);
            } catch (error) {
                console.error('Error initializing IndexedDB:', error);
            }
        }

        initializeDb();
    }, []);

    useEffect(() => {
        if (state.descriptionObjects.file instanceof File)
            setSelectedFile(state.descriptionObjects.file);
    }, [state.descriptionObjects.file])

    useEffect(() => {
        if (!viewOnly && db && state.descriptionObjects.file) {
            getItem(db, STORE_NAME, 'svgKey').then((e) => {
                dispatch(setDescriptionObjectsAction({
                    ...state.descriptionObjects,
                    file: e.file
                }))
            });
        }
    }, [db]);

    const showModal = (updatedNextId, resultTree, polygonProperties, onError, file) => {
        Modal.confirm({
            title: 'Você gostaria de manter os objetos já criados na sua árvore?',
            icon: <ExclamationCircleFilled />,
            content: (
                <div>
                    <p>Você está carregando um SVG que possui um conjunto de objetos predefinidos.</p>
                    <p><b>Caso escolha não, todo os objetos da árvore serão removidos</b></p>
                </div>
            ),
            okText: 'Não',
            cancelText: 'Sim',
            onOk() {
                handleOk(updatedNextId, resultTree, polygonProperties, onError, file);
            },
            onCancel() {
                handleCancel(polygonProperties, onError, file);
            },
        });
    };

    const handleOk = (updatedNextId, resultTree, polygonProperties, onError, file) => {
        try {
            dispatch(setDescriptionObjectsAction({
                ...initialState.descriptionObjects,
                file: file,
                nextId: updatedNextId,
                objects: resultTree,
                polygonProperties: polygonProperties,
            }))
            message.success(`Upload do arquivo ${file.name} concluído.`);
        } catch (error) {
            onError();
            message.error(`Upload do arquivo ${file.name} falhou.`);
        }

    };

    const handleCancel = (polygonProperties, onError, file) => {
        try {
            dispatch(setDescriptionObjectsAction({
                ...state.descriptionObjects,
                file: file,
                nextId: state.descriptionObjects.nextId,
                objects: descriptionObjects,
                polygonProperties: polygonProperties
            }))
            setSelectedFile(file);
            message.success(`Upload do arquivo ${file.name} concluído.`);
        } catch (error) {
            onError();
            message.error(`Upload do arquivo ${file.name} falhou.`);
        }
    };

    useEffect(() => {
        setSelectedObjectState(state.descriptionObjects.selectedObject);
        if (mode === 1)
            setMode(0);
    }, [state.descriptionObjects.selectedObject])

    useEffect(() => {
        setDescriptionObjects(state.descriptionObjects.objects);
        const found = state.descriptionObjects.objects.find(element => element.key === state.descriptionObjects.selectedObject.key);
        if (found !== undefined)
            dispatch(setSelectedObject(found));
    }, [state.descriptionObjects.objects])

    useEffect(() => {
        setSelectedSegment(state.descriptionObjects.segment.selectedSegment);
    }, [state.descriptionObjects.segment.selectedSegment]);

    useEffect(() => {
        setSelectedContact(state.descriptionObjects.contacts.selectedContact);
    }, [state.descriptionObjects.contacts.selectedContact])

    useEffect(() => {
        layers.map((layer) => {
            if (visible[layer.prettyName])
                layer.style.display = "block";
            else
                layer.style.display = "none";
            return layer;
        })
    }, [visible])

    useEffect(() => {
        if (ref.current)
            setSvgDimensions({ width: ref.current.clientWidth, height: ref.current.clientHeight })
    }, [refresh, mode])

    //Updating object and segment tree when change objects name by click
    useEffect(() => {
        const { id: polygonId, ctrlKey } = selectedPolygon;
        const objects = state.descriptionObjects.objects;
        const selectedObject = state.descriptionObjects.selectedObject;
        const polygonProperties = state.descriptionObjects.polygonProperties || {};

        if (mode === 2) {
            const key = findObj(state.descriptionObjects.objects, polygonId);
            const newSelectedKeys = updateSelectedKeys(selectedObject, key, ctrlKey);
            dispatch(setSelectedObject(newSelectedKeys));
        }

        if (mode === 1 && selectedObject.length === 1) {
            const highlightSVG = new HighlightSVG("selected");
            const obj = findObjByKey(objects, selectedObject[0]);
            if (obj === null) return;

            const polygonIds = obj.properties.polygonId || [];
            const newPolygonIds = updateSelectedKeys(polygonIds, polygonId, ctrlKey);

            const bbox = highlightSVG.getBboxFromPolygonsId(newPolygonIds);
            const len = newPolygonIds.length;
            const newProperties = len > 0 ? {
                area: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].area || 0), 0),
                heightMax: Math.max(...newPolygonIds.map(id => polygonProperties[id].heightMax || 0)),
                heightMean: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].heightMean || 0), 0) / len,
                heightMedian: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].heightMedian || 0), 0) / len,
                heightMin: Math.min(...newPolygonIds.map(id => polygonProperties[id].heightMin || 0)),
                heightMode: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].heightMode || 0), 0) / len,
                widthMax: Math.max(...newPolygonIds.map(id => polygonProperties[id].widthMax || 0)),
                widthMean: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].widthMean || 0), 0) / len,
                widthMedian: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].widthMedian || 0), 0) / len,
                widthMin: Math.min(...newPolygonIds.map(id => polygonProperties[id].widthMin || 0)),
                widthMode: newPolygonIds.reduce((sum, id) => sum + (polygonProperties[id].widthMode || 0), 0) / len,
                polygonId: newPolygonIds,
                width: bbox.maxX - bbox.minX,
                thickness: bbox.maxY - bbox.minY
            } : {
                area: 0,
                heightMax: 0,
                heightMean: 0,
                heightMedian: 0,
                heightMin: 0,
                heightMode: 0,
                widthMax: 0,
                widthMean: 0,
                widthMedian: 0,
                widthMin: 0,
                widthMode: 0,
                polygonId: [],
                width: 0,
                thickness: 0
            };
            dispatch(
                setObjectProperties(
                    updateObjectHull(
                        selectedObjectState[0],
                        descriptionObjects,
                        newProperties,
                    )
                )
            );
            const content = newPolygonIds.includes(polygonId)
                ? `Polígono ${polygonId} atribuído ao objeto ${obj.title}`
                : `Polígono ${polygonId} removido do objeto ${obj.title}`
            message.open({ type: 'info', content: content, duration: 2 });
        }
    }, [selectedPolygon])

    //Draw object
    //TODO: Draw segment
    useEffect(() => {
        const objects = state.descriptionObjects.objects;
        const segments = state.descriptionObjects.segment.tree;
        const contacts = state.descriptionObjects.contacts.tree;

        const highlightSVG = new HighlightSVG("selected");
        highlightSVG.cleanSVG();

        if (selectedObjectState.length > 0) {
            highlightSVG.selectSVG(selectedObjectState, objects);
            return;
        }
        if (selectedSegment.length > 0) {
            selectedSegment.forEach(segmentId => {
                const segment = findObjByKey(segments, segmentId);
                if (segment && segment.children && segment.children.length > 0) {
                    const [object1, object2] = segment.children;
                    highlightSVG.selectSVG([object1.original_key, object2.original_key], objects);
                    highlightSVG.drawSegmentPath(object1, object2, objects);
                }
            });
            return;
        }
        if (selectedContact.length > 0) {
            selectedContact.forEach(contactId => {
                const contact = findObjByKey(contacts, contactId)
                contact?.children?.forEach((segmentId) => {
                    const segment = findObjByKey(segments, segmentId.original_key)
                    if (segment && segment.children && segment.children.length > 0) {
                        const [object1, object2] = segment.children;
                        highlightSVG.selectSVG([object1.original_key,object2.original_key], objects);
                        highlightSVG.drawSegmentPath(object1, object2, objects);
                }
                });
            });
            return;
        }
    }, [selectedObjectState, selectedSegment, selectedContact, layers, state.descriptionObjects.objects])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            setFileList([])
            return;
        } else {
            const objectUrl = URL.createObjectURL(selectedFile);
            setPreview(objectUrl);
            setFileList([{ uid: Date.now(), name: selectedFile.name, originFileObj: selectedFile, status: 'done' }])
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [selectedFile])

    useEffect(() => {
        if (svgPan) {
            svgPan.resize();
            svgPan.fit();
            svgPan.center();
        }
    }, [refresh]);

    useEffect(() => {
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            box: { ...state.descriptionObjects.box, ctm: ctm }
        }));
    }, [ctm])

    const loadSVG = () => {
        getLayers();
        handleOnload();

        const svgPan = svgPanZoom('#envoltória-objetos-geologicos', {
            zoomEnabled: true,
            controlIconsEnabled: true,
            mouseWheelZoomEnabled: true,
            maxZoom: 100,
            zoomScaleSensitivity: 0.3,
            onPan: () => {
                //TODO: prevent user to select polygons
            },
             onUpdatedCTM: debounce((e) => {
                 setCTM(e)
             }, 100)
        });
        setSvgPan(svgPan);
    }

    const getLayers = () => {
        const obj = document.querySelector("object");
        const svgDoc = obj.contentDocument;
        let rawLayers = Array.from(svgDoc.querySelectorAll("g"));

        let layers = rawLayers.map(e => {
            e.prettyName = e.getAttribute("inkscape:label")
            return e.prettyName ? e : null
        }).filter((e => e))

        layers = layers.reverse();
        setLayers(layers);
    }

    const onPolygonClick = (event, elem) => {
        if (!viewOnly) {
            const { ctrlKey } = event;
            const box = elem.getBBox();
            setPolygonBBox({ x: box.x, y: box.y, width: box.width, height: box.height });
            setSelectedPolygon({ id: elem.id, ctrlKey: ctrlKey });
        }
    }

    const updateObjectHull = (key, data, polygonProperties) => {
        const updateData = (data, key) => data.map(e => {
            if (e.children)
                e.children = updateData(e.children, key);
            if (e.key === key) {
                return {
                    ...e,
                    properties: {
                        ...e.properties,
                        ...polygonProperties,
                    }
                };
            }

            return e;
        })

        const newArray = updateData(data, key);

        return newArray;
    }

    const updateSegmentHull = (key, data, polygonId) => {
        const updateData = (data, key) => data.map(e => {
            if (e.children)
                e.children = updateData(e.children, key);
            else if (e.original_key === key) {
                return {
                    ...e,
                    title: polygonId,
                    polygonId: [polygonId],
                };
            }
            return e;
        })
        const newArray = updateData(data, key);
        return newArray;
    }

    const handleOnload = () => {
        const obj = document.querySelector("object");
        const svgDoc = obj.contentDocument;
        const textContentWithoutHover = ".svg-pan-zoom_viewport path, .svg-pan-zoom_viewport polygon {pointer-events:all;} .svg-pan-zoom_viewport path.selected, .svg-pan-zoom_viewport polygon.selected { stroke: #ff00ff !important; stroke-width:6px !important; vector-effect: non-scaling-stroke !important; } "
        const textContent = ".svg-pan-zoom_viewport path, .svg-pan-zoom_viewport polygon {pointer-events:all;} .svg-pan-zoom_viewport path:hover, .svg-pan-zoom_viewport polygon:hover, .svg-pan-zoom_viewport path.selected, .svg-pan-zoom_viewport polygon.selected { stroke: #ff00ff !important; stroke-width:6px !important; vector-effect: non-scaling-stroke !important; } ";
        let styleElement = svgDoc.createElementNS("http://www.w3.org/2000/svg", "style");
        styleElement.textContent = viewOnly ? textContentWithoutHover : textContent;
        svgDoc.querySelector("svg").appendChild(styleElement);
        let polygons = Array.from(svgDoc.querySelectorAll("path, polygon"));
        polygons.forEach(elem => {
            // create toottip with title element
            const titleElement = document.createElementNS("http://www.w3.org/2000/svg", 'title');
            titleElement.textContent = elem.id;
            elem.appendChild(titleElement);
            elem.onclick = (event) => {
                onPolygonClick(event, elem);
            }
        })

        // Get scale text and remove unit with regex (m, km)
        const scaleDomainX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL text").textContent.replace(/\D/g, ""));
        const scaleDomainY = Number(svgDoc.querySelector("#ESCALA_VERTICAL text").textContent.replace(/\D/g, ""));
        setScaleDomain({ x: scaleDomainX, y: scaleDomainY })

        // Get the canvas width and height of scale
        const scaleRangeX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL rect").getAttribute("width"));
        const scaleRangeY = Number(svgDoc.querySelector("#ESCALA_VERTICAL rect").getAttribute("height"));
        setScaleRange({ x: scaleRangeX, y: scaleRangeY });

    }

    const getRealWidth = (width) => {
        return (scaleDomain.x * width) / scaleRange.x;
    }

    const getRealHeight = (height) => {
        return (scaleDomain.y * height) / scaleRange.y;
    }

    const customRequest = async ({ file, onSuccess, onError }) => {
        setFileList([{ uid: Date.now(), name: file.name, originFileObj: file, status: 'uploading' }]);
        try {
            const response = await uploadSvgToBackend(file);
            if (response.status === 200) {
                onSuccess("ok", null);
                const [resulTree, updatedNextId, polygonProperties] = buildTree(
                    response.data,
                    initialState.descriptionObjects.nextId
                );
                const store = db?.transaction(STORE_NAME, 'readwrite').objectStore(STORE_NAME);
                store.put({ key: 'svgKey', file: file });
                showModal(updatedNextId, resulTree, polygonProperties, onError, file);
            } else {
                onError();
                message.error(`Upload do arquivo ${file.name} falhou.`);
            }
        } catch (error) {
            onError();
            setFileList([]);
            message.error(`Erro ao processar o arquivo: ${error.message}`);
        }
    };

    const uploadSvgToBackend = async (file) => {
        const url = 'http://api-geoserver.inf.ufrgs.br/svg/process_tree_contact';
        const formData = new FormData();
        formData.append('svgFile', file);
        try {
            const response = await axios.post(url, formData)
            return response
        } catch (error) {
            throw error;
        }
    };

    const buildTree = (tree, nextId) => {
        const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;
        const polygonProperties = {};

        const getIcon = (type) => {
            switch (type) {
                case UNIT:
                    return <tbIcons.TbChartRadar />;
                case FACIES_ASSOCIATION:
                    return <FaIcons.FaRegCircle />;
                case ARCH_ELEMENT:
                    return <tbIcons.TbChartBubble />;
                default:
                    return;
            }
        }

        const recursiveBuildObjects = (data) => {
            const geologicalObjectType = data.type;
            const id = (geologicalObjectType in nextId) ? nextId[geologicalObjectType] + 1 : 1;
            const key = geologicalObjectType + id;
            nextId[geologicalObjectType] = id;
            const children = data.children ? data.children.map(child => recursiveBuildObjects(child)) : [];
            const properties = {
                area: data.area,
                heightMean: data.heightMean,
                heightMedian: data.heightMedian,
                heightMode: data.heightMode,
                heightMax: data.heightMax,
                heightMin: data.heightMin,
                widthMean: data.widthMean,
                widthMedian: data.widthMedian,
                widthMode: data.widthMode,
                widthMax: data.widthMax,
                widthMin: data.widthMin,
                width: data.width,
                thickness: data.thickness
            }
            polygonProperties[data.id] = properties;
            return {
                title: data.label,
                key: key,
                idNumber: id,
                properties: {
                    polygonId: [data.id],
                    ...properties
                },
                bbox: {},
                geologicalObjectType: geologicalObjectType,
                children: children,
                icon: getIcon(geologicalObjectType),
            }
        }

        const resultTree = tree.children.map(object => recursiveBuildObjects(object))
        return [resultTree, nextId, polygonProperties]
    };

    const draggerProps = {
        name: 'file',
        maxCount: 1,
        accept: 'image/svg+xml',
        fileList,
        onRemove() {
            dispatch(setDescriptionObjectsAction(initialState.descriptionObjects))
        }
    };

    const items = layers.map(e => {
        return (
            {
                label: e.prettyName,
                key: e.prettyName,
                icon: visible[e.prettyName] ? <EyeOutlined /> : <EyeInvisibleOutlined />,
            }
        );
    })

    const menuProps = {
        items: items,
        onClick: (e) => {
            const { key } = e;
            setVisible((state) => ({ ...state, [key]: !state[key] }))
            e.stopPropagation();
        },
    };

    const modeOnChange = (value) => {
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            pit: (value === 4) ? { ctm: ctm, left: 0 } : {}
        }));
        setMode(value === mode ? 0 : value);
    }

    return (
        <>
            <div>
                <Spin indicator={antIcon} spinning={loading} tip="Loading">
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        {selectedFile && (
                            <>
                                <Row id="layer" gutter={0}>
                                    <Col span={24}>
                                        <object
                                            data={preview}
                                            id="envoltória-objetos-geologicos"
                                            type="image/svg+xml"
                                            width="100%"
                                            alt="Objetos"
                                            aria-label="Objetos"
                                            onLoad={loadSVG}
                                            ref={ref}
                                            style={{ maxHeight: window.innerHeight / 3 }}
                                        />
                                        <div className="svg-toolbar">
                                            <Row align='middle'>
                                                {!viewOnly &&
                                                    <Tooltip title="Atribuição">
                                                        <Button
                                                            type={mode === 1 ? 'primary' : 'default'}
                                                            onClick={() => modeOnChange(1)}
                                                            style={{ borderColor: '#ffffff' }}
                                                            disabled={selectedObjectState.length != 1}
                                                        >
                                                            <LinkOutlined style={{ display: "inline-flex" }} />
                                                        </Button>
                                                    </Tooltip>
                                                }
                                                <Tooltip title="Seleção">
                                                    <Button
                                                        type={mode === 2 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(2)}
                                                        style={{ borderColor: '#ffffff' }}
                                                    >
                                                        <SelectOutlined style={{ display: "inline-flex" }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Curva de proporção">
                                                    <Button
                                                        type={mode === 3 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(3)}
                                                        style={{ borderColor: '#ffffff' }}
                                                    >
                                                        <AreaChartOutlined style={{ display: "inline-flex" }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Visualização do poço">
                                                    <Button
                                                        type={mode === 4 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(4)}
                                                        style={{ borderColor: '#ffffff' }}
                                                    >
                                                        <BarChartOutlined style={{ display: "inline-flex" }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Camadas">
                                                    <Dropdown menu={menuProps} placement={'bottom'}>
                                                        <Button
                                                            style={{ borderColor: '#ffffff' }}
                                                        >
                                                            <EyeOutlined style={{ marginLeft: 0, display: "inline-flex" }} />
                                                        </Button>
                                                    </Dropdown>
                                                </Tooltip>
                                            </Row>
                                        </div>
                                        {mode == 3 && <Window ctm={ctm} />}
                                        {mode == 4 &&
                                            <>
                                                <div id="slider-container">
                                                    <div
                                                        id="vertical-line"
                                                        style={{
                                                            left: value - 1,
                                                            top: -(svgDimensions.height + 6),
                                                            height: (svgDimensions.height + 6),
                                                        }}
                                                    >
                                                    </div>
                                                    <Slider
                                                        defaultValue={30}
                                                        max={svgDimensions.width}
                                                        value={value}
                                                        onChange={(value) => setValue(value)}
                                                        onAfterChange={(value) => dispatch(setDescriptionObjectsAction({
                                                            ...state.descriptionObjects,
                                                            pit: { ctm: ctm, left: value }
                                                        }))}
                                                        style={{ margin: 0, padding: 0 }}
                                                        tooltip={{ formatter: null }}
                                                        keyboard={false}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </>
                        )}
                        { !viewOnly &&
                            <Dragger
                                {...draggerProps}
                                customRequest={customRequest}
                            >
                                <p className="ant-upload-text">Clique ou arraste um arquivo para a área de upload</p>
                                <p className="ant-upload-hint">
                                    Suporte apenas para arquivos svg.
                                </p>
                            </Dragger>
                        }
                    </Space>
                </Spin>
            </div>
        </>
    )
}

export default Hull;

function findObj(data, key) {
    const stack = data.slice();

    while (stack.length > 0) {
        const current = stack.pop();
        if (current.properties.polygonId?.includes(key))
            return current.key;
        else if (current.children && current.children.length > 0)
            stack.push(...current.children);
    }

    return null;
}

function findObjByKey(data, key) {
    const stack = data.slice();

    while (stack.length > 0) {
        const current = stack.pop();
        if (current.key === key)
            return current;
        else if (current.children && current.children.length > 0)
            stack.push(...current.children);
    }

    return null;
}
import React, { useEffect, useState, useContext, useRef } from 'react';
import { store } from '../../../../Store';
import { ObjectTypes, ScaleTypes } from "../types";
import Highcharts from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import { chartColors, scaleTypeMarker } from './chartColors';
import { Radio, Row, Col, Space, Select, Card } from 'antd';
import FloatLabel from "../../../../FloatLabel";
import { HighlightSVG } from "./../highlightSVG";
import { setSelectedObject, setDescriptionObjectsAction } from '../../../../Store/actions';
import { round } from 'lodash';

HC_exporting(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const { Option } = Select;
const { ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;
const { COMPLEX, COMPOUND, INDIVIDUAL } = ScaleTypes;

const getTypeName = (type) => {
    let UNDEFINED = "Não definido";

    if (type === undefined) {
        return UNDEFINED;
    }

    return type;
}

const getMaxDepth = (node) => {
    if (!node || !node.children?.length)
        return 0;
    const depths = node.children.map(child => getMaxDepth(child));
    return Math.max(...depths) + 1;
}

const getNodesAtLevel = (node, level, currentLevel = 0) => {
    if (!node || !node.children || currentLevel >= level)
        return [];
    else if (level === currentLevel + 1)
        return node.children;
    else
        return node.children.map(child => getNodesAtLevel(child, level, currentLevel + 1)).flat();
}

const getCharData = (nodes, chartType, architecturalTypeColors) => {
    let chartDataCount = {};
    let chartData = [];
    let type = "";

    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].geologicalObjectType === ARCH_ELEMENT) {
            type = getTypeName(nodes[i].architecturalElementType);

            if (chartDataCount[type] === undefined)
                chartDataCount[type] = nodes[i].properties.area;
            else
                chartDataCount[type] += nodes[i].properties.area;
        }
        else if (nodes[i].geologicalObjectType === FACIES_ASSOCIATION) {
            if (nodes[i].properties.typeFaciesAssociation) {
                type = getTypeName(nodes[i].properties.typeFaciesAssociation);

                if (chartDataCount[type] === undefined)
                    chartDataCount[type] = nodes[i].properties.area;
                else
                    chartDataCount[type] += nodes[i].properties.area;
            }
        }

    }

    for (const [key, value] of Object.entries(chartDataCount)) {
        if (chartType === 'pie')
            chartData.push({ name: key, y: value, color: architecturalTypeColors[key] })
        if (chartType === 'bar')
            chartData.push({ name: key, data: [value], color: architecturalTypeColors[key] })
    }

    return chartData;
}

export const ArchElementPropotionCharts = (props) => {
    const { refresh } = props;
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [maxDepth, setMaxDepth] = useState(0);
    const architecturalTypeColors = state.architecturalTypeColors;
    const [level, setLevel] = useState(0);

    useEffect(() => {
        const selectedObject = state.descriptionObjects.selectedObject;
        const depths = selectedObject.map((key) => {
            const node = findObj(state.descriptionObjects.objects, key);
            return getMaxDepth(node);
        });
        const localMaxDepth = Math.max(...depths);
        setMaxDepth(localMaxDepth > 0 ? localMaxDepth : 0);
        if(localMaxDepth < level)
            setLevel(0);
    }, [state.descriptionObjects.selectedObject, state.descriptionObjects.objects]);

    const handleChange = (value) => {
        setLevel(parseInt(value));
    };

    return (
        <Card style={{width: '100%'}}>
            <Row>Nível</Row>
            <select style={{ width: '20%'}} value={level} onChange={e => handleChange(e.target.value)} disabled={level === undefined}>
                <option value={0}>0 - Todos os níveis</option>
                {[...Array(maxDepth)].map((_, i) =>
                    <option key={i} value={i + 1}> {i + 1} </option>
                )}
            </select>
            <Row>
                <PieChart refresh={refresh} level={level}/>
                <BarChart refresh={refresh} level={level}/>
            </Row>
        </Card>
    )
}

// Função que retorna todos os nós de uma árvore, percorrendo-a em profundidade
const getAllNodes = (node) => {
    let nodes = [node];
    if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
            nodes = nodes.concat(getAllNodes(child));
        });
    }
    return nodes;
};


export const PieChart = (props) => {
    const { refresh, level } = props;
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const architecturalTypeColors = state.architecturalTypeColors;
    const [chartOptions, setChartOptions] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: undefined
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'Elemento Arquitetural',
            colorByPoint: true,
        }],
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadPDF", "downloadSVG"]
                }
            }
        }
    });
    const chartComponent = useRef({});

    useEffect(() => {
        const selectedObject = state.descriptionObjects.selectedObject;
        
        const nodes = selectedObject.map((key) => {
            const node = findObj(state.descriptionObjects.objects, key);
            // Se o nível for 0, retorna todos os nós
            return level === 0 ? getAllNodes(node) : getNodesAtLevel(node, level);
        }).flat();

        setChartOptions({
            series: [
                { data: getCharData(nodes, 'pie', architecturalTypeColors) }
            ]
        });
    }, [level, state.descriptionObjects.objects, state.descriptionObjects.selectedObject, state.descriptionObjects.selectedObject.children, architecturalTypeColors]);

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [refresh]);

    return (
        <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
}

export const BarChart = (props) => {
    const { refresh, level } = props;
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const architecturalTypeColors = state.architecturalTypeColors;
    const { ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'bar'
        },
        title: {
            text: 'Proporção de elementos arquiteturais'
        },
        xAxis: {
            categories: ['2020/21']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Goals'
            }
        },
        legend: {
            reversed: true
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            series: {
                stacking: 'percent',
                dataLabels: {
                    enabled: true,
                    format: '{point.percentage:.1f} %'
                }
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadPDF", "downloadSVG"]
                }
            }
        },
    });
    const chartComponent = useRef({});

    useEffect(() => {
        const selectedObject = state.descriptionObjects.selectedObject;
        
        const nodes = selectedObject.map((key) => {
            const node = findObj(state.descriptionObjects.objects, key);
            // Se o nível for 0, retorna todos os nós
            return level === 0 ? getAllNodes(node) : getNodesAtLevel(node, level);
        }).flat();

        setChartOptions({
            series: getCharData(nodes, 'bar', architecturalTypeColors)
        });
    }, [level, state.descriptionObjects.objects, state.descriptionObjects.selectedObject, state.descriptionObjects.selectedObject.children, architecturalTypeColors]);

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [refresh])

    return (
        <div>
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    )
}

export const ScatterChart = (props) => {
    const { refresh } = props;
    const { UNIT } = ObjectTypes;
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const architecturalTypeColors = state.architecturalTypeColors;
    const LOG = 1, LINEAR = 2;
    const [value, setValue] = useState(LOG);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'scatter',
            zoomType: 'xy',
            events: {
                render: function () {
                    var legendWidth = this.chartWidth * 0.75;
                    let itemWidth = Math.floor(legendWidth / 5);
                    this.legend.options.itemWidth = itemWidth;
                    this.legend.options.width = legendWidth;
                    this.render();
                },
            }
        },
        title: {
            text: 'Razão de Aspecto',
            align: 'left'
        },
        xAxis: {
            title: {
                text: 'Largura'
            },
            labels: {
                format: '{value} m'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            min: 1,
        },
        yAxis: {
            title: {
                text: 'Altura'
            },
            labels: {
                format: '{value} m'
            },
            min: 1,
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 4.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        },
                    }
                },
                events: {
                    click: function (event) {
                        const point = event.point;
                        if (point) {
                            dispatch(setSelectedObject([point.key]));
                        }
                    },
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                }
            },
            series: {
                dataLabels: false,
                events: {
                    legendItemClick: function () {
                        const index = this.index;
                        const visible = !this.visible;
                        const series = this.chart.series;

                        series.forEach((serie) => {
                            serie.points.forEach((point) => {
                                const { scaleType, architecturalElementType } = point;
                                let scaleVisibility = true,
                                    architecturalVisibility = true;
                                if (scaleType !== null)
                                    scaleVisibility = (scaleType === index) ? visible : series[scaleType].visible;
                                if (architecturalElementType !== null)
                                    architecturalVisibility = (architecturalElementType === index) ? visible : series[architecturalElementType].visible;
                                point.setVisible(scaleVisibility && architecturalVisibility);
                            })
                        })
                    }
                },
            }
        },
        tooltip: {
            formatter: function () {
                let xValue = this.x;
                let yValue = this.y;
                let name = this.point.name;
                let scaleType = this.point.scaleType;
                let architecturalElement = this.point.architecturalElementType;
                let title;
                if (scaleType !== null && architecturalElement !== null)
                    title = Object.values(ScaleTypes)[scaleType - 1] + ' e ' + Object.keys(architecturalTypeColors)[architecturalElement - 5];
                else if (scaleType !== null)
                    title = Object.values(ScaleTypes)[scaleType - 1];
                else
                    title = Object.keys(architecturalTypeColors)[architecturalElement - 5];
                return (
                    '<tspan style="color:' + this.point.color + '; fill:' + this.point.color +
                    '"> ● </tspan>' + '<tspan style="font-size: 10px;">' + title + '</tspan><br/>' +
                    '<tspan>' + name + '</tspan><br/>' +
                    '<tspan>Largura: ' + xValue + '</tspan><br/>' +
                    '<tspan>Altura: ' + yValue + '</tspan><br/>'
                )
            }
        },
        legend: {
            enabled: true,
            align: 'center',
            itemDistance: 5
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadPDF", "downloadSVG"]
                }
            }
        },
    });
    const chartComponent = useRef({});
    const architecturalElementTypes = state.ontologyInformation.architecturalElementTypes;

    const generateSeriesData = (architecturalTypeColors) => {
        // Define the fixed marker and stickyTracking
        const defaultColor = 'black';
        const defaultMarker = 'square';
        const stickyTracking = false;

        // Create the list structure based on the keys and values of the object
        const seriesData = Object.keys(architecturalTypeColors).map(channel => ({
            name: channel, // The name comes from the key
            data: [],      // Empty list, as per the example
            color: architecturalTypeColors[channel], // Color comes from the value associated with the key
            marker: { symbol: defaultMarker }, // Set the default marker
            stickyTracking: stickyTracking     // Set the fixed stickyTracking value
        }));

        seriesData.push(
            { name: "", data: [], marker: { enabled: false } },
            { name: "", data: [], marker: { enabled: false } },
            { name: "", data: [], marker: { enabled: false } },
            { name: "", data: [], marker: { enabled: false } },
            { name: "COMPLEXO", data: [], color: defaultColor, marker: { symbol: scaleTypeMarker[COMPLEX] }, stickyTracking: false },
            { name: "COMPOSTO", data: [], color: defaultColor, marker: { symbol: scaleTypeMarker[COMPOUND] }, stickyTracking: false },
            { name: "INDIVÍDUO", data: [], color: defaultColor, marker: { symbol: scaleTypeMarker[INDIVIDUAL] }, stickyTracking: false },
            { name: "", data: [], marker: { enabled: false } }
        );

        return seriesData;
    };


    const getData = (data) => {
        const defaultColor = 'black';
        const defaultMarker = 'square'
        const result = generateSeriesData(architecturalTypeColors)
        
        const traverseTree = (data) => {
            for (var i = 0; i < data.length; i++) {
                const { key, title, geologicalObjectType, architecturalElementType, properties, children } = data[i];
                const { scaleType, width, thickness } = properties;
                const point = {
                    x: width,
                    y: thickness,
                    name: title,
                    key: key,
                    marker: { symbol: defaultMarker, states: { select: { radius: 8, fillColor: defaultColor } } },
                    color: defaultColor,
                    scaleType: null,
                    architecturalElementType: null,
                }

                if (children)
                    traverseTree(children);

                if (scaleType === COMPLEX) {
                    point.marker.symbol = scaleTypeMarker[COMPLEX];
                    point.scaleType = 1;
                } else if (scaleType === COMPOUND) {
                    point.marker.symbol = scaleTypeMarker[COMPOUND];
                    point.scaleType = 2;
                } else if (scaleType === INDIVIDUAL) {
                    point.marker.symbol = scaleTypeMarker[INDIVIDUAL];
                    point.scaleType = 3;
                }

                if (architecturalElementType) {
                    let color = architecturalTypeColors[architecturalElementType];
                    point.color = color;
                    point.architecturalElementType = Object.keys(architecturalTypeColors).indexOf(architecturalElementType) + 5;
                    point.marker.states.select.fillColor = color;
                }


                if (point.scaleType !== null)
                    result[point.scaleType].data.push(point);
                if (point.architecturalElementType !== null)
                    result[point.architecturalElementType].data.push(point);
            }

            return result;
        }

        const result_traversed = traverseTree(data);

        return result_traversed;
    }

    useEffect(() => {
        const objTree = state.descriptionObjects.objects;
        const type = value === LINEAR ? 'linear' : 'logarithmic';
        const tickInterval = value === LINEAR ? '' : 1;
        const minorTickInterval = value === LINEAR ? '' : 0.1;

        setChartOptions({
            ...chartOptions,
            series: getData(objTree),
            xAxis: {
                ...chartOptions.xAxis,
                type: type,
                tickInterval: tickInterval,
                minorTickInterval: minorTickInterval,
            },
            yAxis: {
                ...chartOptions.yAxis,
                type: type,
                tickInterval: tickInterval,
                minorTickInterval: minorTickInterval,
            }
        });

    }, [value, state.descriptionObjects.objects, architecturalTypeColors])

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        const selectedObject = state.descriptionObjects.selectedObject;
        if (chart && selectedObject) {
            let series = chart.series;
            series.forEach((serie) => {
                serie.points.forEach((point) => {
                    const { scaleType, architecturalElementType, key } = point;
                    //console.log(selectedObject, key, selectedObject.includes(key));
                    // Verifica se o ponto está selecionado
                    selectedObject.includes(key) ? point.select(true, true) : point.select(false, true);
                    // Verifica visibilidade do ponto
                    let scaleVisibility = (scaleType !== null) ? series[scaleType].visible : true,
                        architecturalVisibility = (architecturalElementType !== null) ? series[architecturalElementType].visible : true;
                    point.setVisible(scaleVisibility && architecturalVisibility)
                })
            })
        }
    }, [state.descriptionObjects.selectedObject, chartOptions])

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [refresh])

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        ref={chartComponent}
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            <Radio value={1}>Logarítimica</Radio>
                            <Radio value={2}>Linear </Radio>
                        </Space>
                    </Radio.Group>
                </Col>
            </Row>
        </>


    )
}

export const PercentageArea = (props) => {
    const { refresh } = props;
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const architecturalTypeColors = state.architecturalTypeColors

    const [step, setStep] = useState(1);
    const chartComponent = useRef({});
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'area',
            inverted: true,
            events: {
                exportData: function ({ dataRows }) {
                    dataRows.forEach(function (row, i) {
                        if (i === 0) return;

                        let sum = 0;
                        for (let j = 1; j < row.length; j++) {
                            if (typeof row[j] === 'number')
                                sum += row[j];
                        }

                        row[0] = round(row[0], 2);
                        for (let j = 1; j < row.length; j++) {
                            if (typeof row[j] === 'number')
                                row[j] = round(row[j] * 100 / sum, 2);
                        }
                    });
                }
            }
        },
        title: {
            useHTML: true,
            text: '',
            align: 'left'
        },
        accessibility: {
            point: {
                valueDescriptionFormat: '{index}. {point.category}, {point.percentage:.1f}%.'
            }
        },
        yAxis: {
            tickInterval: 10, // Set the interval between tick marks on the y-axis
            max: 100, // Set the maximum value for the y-axis
            labels: {
                format: '{value}%'
            },
            title: {
                enabled: false
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b><br/>',
            split: true
        },
        plotOptions: {
            area: {
                stacking: 'percent',
                marker: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false, // Set to false to hide labels
                },
            }
        },
        legend: {
            reversed: true
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "separator", "downloadPNG", "downloadPDF", "downloadSVG", "downloadCSV"]
                }
            },
        },
    });

    useEffect(() => {
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            box: { ...state.descriptionObjects.box, method: 'object', update: state.descriptionObjects.box.update + 1 }
        }));
    }, [state.descriptionObjects.selectedObject]);

    const handleChange = (value) => {
        setStep(value);
    };

    useEffect(() => {
        const { update, ctm, method } = state.descriptionObjects.box;
        const doc = document.querySelector("object");
        const window = document.getElementById("window");
        const tree = state.descriptionObjects.objects;
        const selectedObject = state.descriptionObjects.selectedObject;

        if (doc == null) {
            return;
        }
        if (method === 'window' && window != null && update > 0) {
            const svg = doc.contentDocument.children[0];
            const { offsetTop, offsetLeft, offsetHeight, offsetWidth } = window;
            const { x, y } = getRealCoord(offsetLeft, offsetTop, ctm, svg);
            const { x: x1, y: y1 } = getRealCoord(offsetLeft + offsetWidth, offsetTop + offsetHeight, ctm, svg);
            const width = x1 - x;
            const height = y1 - y;

            const highlightSVG = new HighlightSVG("selected");
            const box = { x: x, y: y, width: width, height: height }

            setChartOptions({
                ...chartOptions,
                xAxis: {
                    tickInterval: step,
                },
                series: highlightSVG.getProportionInBox(tree, box, step),
            });
        }
        if (method === 'object' && selectedObject.length > 0) {
            const highlightSVG = new HighlightSVG("selected");
            const _tree = selectedObject.map(key => findObj(tree, key));
            const bbox = { x: -50000, y: -50000, width: 100000, height: 100000 };
            setChartOptions({
                ...chartOptions,
                xAxis: {
                    tickInterval: step,
                },
                series: highlightSVG.getProportionInBox(_tree, bbox, step),
            });
        }
    }, [state.descriptionObjects.box.update, step]);

    useEffect(() => {

        const chart = chartComponent.current?.chart;

        if (chart) chart.reflow(false);

    }, [refresh])

    return (
        <Card style={{ height: '100%' }} title="Curva de proporção">
            <div>Passo(m)</div>
            <select style={{ maxWidth: "300px", width: '100%' }} defaultValue={step} onChange={e => handleChange(e.target.value)}>
                <option value={0.1}>0.1</option>
                <option value={1}>1</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
            </select>


            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts}
                options={chartOptions}
            />
        </Card>
    )
}

export const PitChart = (props) => {
    const { refresh } = props;
    const globalState = useContext(store);
    const { state } = globalState;
    const chartComponent = useRef({});
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'columnrange',
        },
        title: {
            text: ""
        },
        yAxis: {
            reversed: true,
            title: {
                text: "Profundidade"
            },
            endOnTick: false,
        },
        xAxis: {
            labels: {
                enabled: false,
            }
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            columnrange: {
                grouping: false
            },
            series: {
                centerInCategory: true,
                borderWidth: 0,
                pointPadding: 0,
                minPointLength: 2
            },
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
        },
    });

    useEffect(() => {
        const { left, ctm } = state.descriptionObjects.pit;
        if (left === undefined || ctm === undefined) {
            setChartOptions({
                ...chartOptions,
                series: [],
            });
            return;
        }

        const doc = document.querySelector("object");
        const svgDoc = doc.contentDocument;
        const svg = svgDoc.children[0];

        const { x: x, y: y } = getRealCoord(left, 0, ctm, svg);

        const highlightSVG = new HighlightSVG("selected");
        const tree = state.descriptionObjects.objects;
        const { series, highest } = highlightSVG.getPit(tree, x);

        setChartOptions({
            ...chartOptions,
            yAxis: {
                ...chartOptions.yAxis,
                min: 0,
                max: highest,
            },
            xAxis: {
                ...chartOptions.xAxis,
                categories: [x],
            },
            series: series,
        });
    }, [state.descriptionObjects.pit]);

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [refresh])

    return (
        <HighchartsReact
            ref={chartComponent}
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
}

const findObj = (data, key) => {
    let result = null;
    const findData = (data, key) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].children)
                findData(data[i].children, key);
            if (data[i].key === key) {
                result = data[i];
            }
        }

        return result;
    }

    return findData(data, key);
}

const getSuperBbox = (svgDoc, polygonsIds) => {
    let minX = Number.MAX_VALUE,
        minY = Number.MAX_VALUE,
        maxX = Number.MIN_VALUE,
        maxY = Number.MIN_VALUE;

    polygonsIds.forEach(id => {
        let bbox = svgDoc.querySelector("#" + id.replaceAll('.', '\\.')).getBBox();
        minX = Math.min(minX, bbox.x);
        minY = Math.min(minY, bbox.y);
        maxX = Math.max(maxX, bbox.x + bbox.width);
        maxY = Math.max(maxY, bbox.y + bbox.height);
    })

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
    }
}

function getRealCoord(x, y, ctm, svg) {
    const pt = svg.createSVGPoint();
    pt.x = x;
    pt.y = y;
    return pt.matrixTransform(ctm.inverse());
}

import { useContext, useState, useEffect, useRef } from "react";
import { Tree, Row, Col, Input, Dropdown, Check, Checkbox, Button } from 'antd';
import { DeleteOutlined, EditOutlined, CopyOutlined, MoreOutlined, ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Space, Popconfirm, Card } from 'antd';
import { store } from '../../../../Store';
import { updateSegments, setCheckedsObjectsSegments, setSelectedSegment, setSelectedObject, deleteDescriptionObject, setObjectProperties, updateContacts, setDescriptionObjectsAction } from '../../../../Store/actions';
import { HighlightSVG } from "../highlightSVG"
import Swal from 'sweetalert2';

import { debounce } from 'lodash';
import { updateSelectedKeys } from "../../../../../utils";

const { Search } = Input;

function updateObj(key, data, propertyName, propertyValue, propertyKey = 'key') {
    const updateData = (data, key) => data.map(e => {
        if (e.children)
            e.children = updateData(e.children, key);
        if (e[propertyKey] === key) {
            return {
                ...e,
                [propertyName]: propertyValue
            };
        }
        return e;
    })

    return updateData(data, key);
}

function findNodeByKey(tree, keyToFind) {
    const stack = [...tree];

    while (stack.length > 0) {
        const node = stack.pop();
        if (node.key === keyToFind) return node;
        if (node.children && node.children.length > 0) stack.push(...node.children);
    }

    return null;
}


function SegmentsForm(props) {
    const { viewOnly, openSegmentsSearch, deletingSegments, height } = props
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [segmentTree, setSegmentTree] = useState(state.descriptionObjects.segment.tree);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isHovered, setIsHovered] = useState(null);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        node: null,
    });
    const [edit, setEdit] = useState({
        key: null,
        value: '',
        newKey: false,
        update: false
    });
    const [checkStatus, setCheckStatus] = useState({
        visible: false,
        checkStrictly: false,
        checkeds: null,
    });
    const [selectAllStatus, setSelectAllStatus] = useState({
        visible: false,
        checked: false
    });
    const inputRef = useRef(null);
    const segmentRef = useRef(null);
    const doubleClickTimeoutRef = useRef(null);
    // Search
    const [expandedKeys, setExpandedKeys] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    useEffect(() => {
        setSelectedKeys(state.descriptionObjects.segment.selectedSegment);
    }, [state.descriptionObjects.segment.selectedSegment])

    //Search
    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
    };

    useEffect(() => {
        const { checkeds } = checkStatus;
        if (deletingSegments) {
            setCheckStatus({ visible: true, checkeds: [], checkStrictly: false });
            setSelectAllStatus({ visible: true, checked: false });
        }
        if (!deletingSegments && checkeds !== null) {
            if (checkeds.length > 0) {
                Swal.fire({
                    title: 'Você tem certeza que deseja remover estes objetos?',
                    text: "Não será possível reverter isto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, apague!',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed)
                        deleteSegment(checkeds);
                    setSelectAllStatus({ visible: false, checked: false });
                    setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
                })
            } else {
                setSelectAllStatus({ visible: false, checked: false });
                setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
            }
        }
    }, [deletingSegments])

    useEffect(() => {
        if (state.descriptionObjects.updatedObjects === 0) return;

        const newSegmentsTree = state.descriptionObjects.segment.tree.filter(segment =>
            segment.children.length && segment.children.every(child =>
                findNodeByKey(state.descriptionObjects.objects, child.original_key) !== null
            )
        );

        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            segment: {
                ...state.descriptionObjects.segment,
                tree: newSegmentsTree,
                updated: state.descriptionObjects.segment.updated + 1
            }
        }));
    }, [state.descriptionObjects.updatedObjects])

    function traverseTree(data) {
        const stack = [...data];
        const result = [];

        while (stack.length > 0) {
            const node = stack.pop();
            if (node.children) {
                result.push(node.key);
            }
        }

        return result;
    }

    function findNodesWithSubstring(tree, substring) {
        const result = [];

        function traverse(node) {
            if (node === null || typeof node !== 'object') {
                return;
            }

            // Checks if the substring is present in the node key
            if (substring != "" && node.hasOwnProperty('title') && typeof node.title === 'string') {
                let key = node.title.toLowerCase();
                if (key.includes(substring.toLowerCase())) {
                    result.push(node.key);
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverse(child);
                });
            }
        }

        tree.forEach(node => traverse(node));

        return result;
    }

    function updateTitles(treeInput, nodesWithSubstring, substring) {
        var tree = treeInput;
        function traverseAndUpdate(node) {
            // console.log(node)
            if (node === null || typeof node !== 'object') {
                return;
            }

            // If the node's key is in the nodesWithSubstring list, update the title
            if (node.hasOwnProperty('key') && typeof node.key === 'string') {
                let title = node.title;
                let key = node.key;
                if (substring != "" && nodesWithSubstring.includes(key)) {
                    let index = title.toLowerCase().indexOf(substring.toLowerCase());

                    let before = title.substring(0, index);
                    let match = title.substring(index, index + substring.length);
                    let after = title.substring(index + substring.length);

                    node.before = before;
                    node.match = match;
                    node.after = after;
                }
                else {
                    node.match = "";
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverseAndUpdate(child);
                });
            }
        }

        tree.forEach(node => traverseAndUpdate(node));
    }

    const onChange = (e) => {
        const { value } = e.target;
        let newExpandedKeys = findNodesWithSubstring(segmentTree, value);
        console.log('onChange', newExpandedKeys)
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
        updateTitles(segmentTree, newExpandedKeys, value);
        onExpand(newExpandedKeys);
        if (newExpandedKeys.length >= 1)
            segmentRef.current.scrollTo({ key: newExpandedKeys[0], align: "top" });
    };

    useEffect(() => {
        if (!openSegmentsSearch)
            updateTitles(segmentTree, [], "key0");
    }, [openSegmentsSearch])

    //Update the tree
    useEffect(() => {
        setSegmentTree(state.descriptionObjects.segment.tree);
    }, [state.descriptionObjects.segment.tree])

    const deleteSegment = (keys) => {
        const newSegmentsTree = segmentTree.filter(node => !keys.includes(node.key));
        const newSelectedSegments = state.descriptionObjects.segment.selectedSegment.filter(key =>
            findNodeByKey(newSegmentsTree, key) !== null
        );
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            segment: {
                ...state.descriptionObjects.segment,
                tree: newSegmentsTree,
                selectedSegment: newSelectedSegments,
                updated: state.descriptionObjects.segment.updated + 1
            }
        }));
    }

    const confirmDeleteSegment = (keys) => {
        Swal.fire({
            title: "Você tem certeza que deseja remover este segmento?",
            text: "Não será possível reverter isto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apague!',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteSegment(keys);
            }
        });
    };

    function getItem(label, key, icon, children, theme) {
        return {
            key,
            icon,
            children,
            label,
            theme,
        };
    }

    const displayInline = { display: "inline-flex" };

    const onClickOutside = () => {
        setContextMenu({ ...contextMenu, visible: false, node: null });
        document.removeEventListener('click', onClickOutside);
    }

    useEffect(() => {
        if (contextMenu.visible) {
            document.addEventListener('click', onClickOutside);
        }
    }, [contextMenu])

    useEffect(() => {
        const segmentTree = state.descriptionObjects.segment.tree;
        const { key, value, newKey, update } = edit;

        if (newKey) {
            inputRef?.current.focus();
        }

        if (update) {
            dispatch(setDescriptionObjectsAction({
                ...state.descriptionObjects,
                segment: {
                    ...state.descriptionObjects.segment,
                    tree: updateObj(key, segmentTree, 'title', value),
                    updated: state.descriptionObjects.segment.updated + 1
                }
            }));
            setEdit({ key: null, value: '', newKey: false, update: false });
        }
    }, [edit]);

    const titleRender = (nodeData) => {
        const originalTitle = nodeData.title;
        const key = nodeData.key;

        let tempTitle = originalTitle;

        if (nodeData.hasOwnProperty('before') && nodeData.hasOwnProperty('after') && nodeData.hasOwnProperty('match') && nodeData.match != "") {
            tempTitle = <span className='search-result-tree'>{nodeData.before}<span className="site-tree-search-value search-result-tree">{nodeData.match}</span>{nodeData.after}</span>;
        }
        else {
            tempTitle = nodeData.title;
        }

        let title = tempTitle

        let hasChildren = "children" in nodeData

        const items = [
            getItem('Renomear', 'rename', <EditOutlined style={displayInline} />),
            getItem('Remover', 'delete', <DeleteOutlined style={displayInline} />)
        ];

        const handleMenuClick = ({ key, keyPath, domEvent }) => {
            const actionMap = {
                'delete': () => confirmDeleteSegment([nodeData.key]),
                'rename': () => setEdit({ ...edit, key: nodeData.key, value: nodeData.title, newKey: true }),
            };

            domEvent.stopPropagation();
            const action = keyPath.reverse()[0];
            const actionFunction = actionMap[action];
            if (actionFunction) {
                actionFunction();
            }
            setContextMenu({ ...contextMenu, visible: false, node: null });
            document.removeEventListener('click', onClickOutside);
        }

        const menuProps = {
            items,
            onClick: handleMenuClick,
        };

        const handleKeyDown = (event) => {
            const ENTER = 13;
            const ESCAPE = 27;

            if (event.keyCode === ENTER)
                setEdit({ ...edit, update: true })
            else if (event.keyCode === ESCAPE)
                setEdit({ ...edit, key: null })
        }

        const handleMouseEnter = () => {
            setIsHovered(key);
        };

        const handleMouseLeave = () => {
            setIsHovered(null);
        };

        const handleDoubleClick = () => {
            if (hasChildren)
                setEdit({ ...edit, key: key, value: title, newKey: true })
        }

        const openContextMenu = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setContextMenu({ ...contextMenu, visible: true, node: key });
        }

        return (
            <Row
                gutter={6}
                wrap={false}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onContextMenu={openContextMenu}
                onDoubleClick={handleDoubleClick}
            >
                <Col flex="none">
                </Col>
                {
                    edit.key === key
                        ?
                        <Col flex="auto">
                            <Input
                                ref={inputRef}
                                size='small'
                                value={edit.value}
                                onChange={(e) => setEdit({ ...edit, value: e.target.value })}
                                onKeyDown={handleKeyDown}
                                autoFocus
                            />
                        </Col>
                        :
                        <Col
                            flex="auto"
                            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {title}
                        </Col>
                }
                {
                    hasChildren && (isHovered == key || edit.key === key || (contextMenu.visible && contextMenu.node === key))
                        ?
                        <Col flex="none" style={{ flexShrink: "0" }}>
                            <Dropdown
                                menu={menuProps}
                                open={contextMenu.visible && contextMenu.node === key}
                                onClick={openContextMenu}
                                autoFocus
                            >
                                <MoreOutlined
                                    aria-label="add product name to cart"
                                    style={{
                                        display: "inline-flex",
                                        color: contextMenu.node === key ? "#0000EE" : "black",
                                        fontSize: "15px"
                                    }}
                                />
                            </Dropdown>
                        </Col>
                        :
                        <></>
                }
            </Row>
        )
    }

    const onSelect = (keys, event) => {
        const { node: { key }, nativeEvent: { ctrlKey } } = event;
        const newSelecteds = updateSelectedKeys(selectedKeys, key, ctrlKey);
        dispatch(setSelectedSegment(newSelecteds));
    }

    const onChangeSelectAll = (e) => {
        const checked = e.target.checked;
        const allKeys = traverseTree(segmentTree);
        setSelectAllStatus({ ...selectAllStatus, checked: checked });
        setCheckStatus({ ...checkStatus, checkeds: checked ? allKeys : [] });
    };

    const onCheck = (checkedKeys, info) => {
        console.log(checkedKeys, info);
        if (!selectAllStatus.checked)
            setCheckStatus({ ...checkStatus, checkeds: checkedKeys });
    };

    return (
        <>
            {openSegmentsSearch &&
                <Search
                    style={{
                        backgroundColor: 'white',
                        padding: 8,
                        border: 'none'
                    }}
                    placeholder='Pesquisar'
                    onChange={debounce(onChange, 300)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
                />
            }

            {selectAllStatus.visible &&
                <div style={{ paddingLeft: 36, lineHeight: 1.5715, paddingBottom: 4 }}>
                    <Checkbox checked={selectAllStatus.checked} onChange={onChangeSelectAll}>
                        <span style={{ paddingLeft: 4 }}>
                            Selecionar tudo
                        </span>
                    </Checkbox>
                </div>
            }

            <Tree
                ref={segmentRef}
                blockNode
                treeData={segmentTree}
                showLine={{ showLeafIcon: false }}
                titleRender={titleRender}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                multiple
                height={height - (openSegmentsSearch ? 48 : 0) - (selectAllStatus.visible ? 26 : 0)}

                //Check
                onCheck={onCheck}
                checkable={checkStatus.visible}
                checkStrictly={checkStatus.checkStrictly}
                checkedKeys={checkStatus.checkeds}

                //Search
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                style={{ paddingLeft: 12 }}

            />
        </>
    );
}

export default SegmentsForm;
import { useContext, useState, useEffect, useRef } from "react";
import { Tree, Row, Col, Input, Dropdown, Check, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, CopyOutlined, MoreOutlined, ControlOutlined } from '@ant-design/icons';
import { Space, Popconfirm, Card } from 'antd';
import { store } from '../../../../Store';
import { setSelectedContact, updateContacts, setDescriptionObjectsAction } from '../../../../Store/actions';
import { HighlightSVG } from "../highlightSVG"
import Swal from 'sweetalert2';
import { ContactTypes } from "../types";
import Sharp from "../../../../../assets/sharp.svg"
import Cryptic from "../../../../../assets/cryptic.svg"
import Faulted from "../../../../../assets/faulted.svg"
import Erosive from "../../../../../assets/erosive.svg"
import Gradational from "../../../../../assets/gradational.svg"
import LagDeposit from "../../../../../assets/lagDeposit.svg"
import Undefined from "../../../../../assets/undefined.png"

import { debounce } from 'lodash';
import { updateSelectedKeys } from "../../../../../utils";

const { Search } = Input;

const displayInline = { display: "inline-flex" };

const InvisibleIcon = () => (
    <EditOutlined style={{ color: 'transparent' }} aria-hidden="true" />
);


function getItem(label, key, icon, children, theme) {
    return {
        key,
        children,
        label,
        theme,
        icon,
    };
}

function deleteKeys(data, keysToDelete) {
    return data.filter(e => !keysToDelete.includes(e.key));
}

function updateObj(key, data, propertyName, propertyValue, propertyKey = 'key') {
    const updateData = (data, key) => data.map(e => {
        if (e.children)
            e.children = updateData(e.children, key);
        if (e[propertyKey] === key) {
            return {
                ...e,
                [propertyName]: propertyValue
            };
        }
        return e;
    })

    return updateData(data, key);
}

function findNodeByKey(tree, keyToFind) {
    const stack = [...tree];

    while (stack.length > 0) {
        const node = stack.pop();
        if (node.key === keyToFind) return node;
        if (node.children && node.children.length > 0) stack.push(...node.children);
    }

    return null;
}

function ContactsForm(props) {
    const { viewOnly, openSearchBar, deletingContacts, height } = props
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const [contactsTree, setContactsTree] = useState(state.descriptionObjects.contacts.tree);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isHovered, setIsHovered] = useState(null);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        node: null,
    });
    const [edit, setEdit] = useState({
        key: null,
        value: '',
        newKey: false,
        update: false,
        contactType: null
    });
    const [checkStatus, setCheckStatus] = useState({
        visible: false,
        checkStrictly: false,
        checkeds: null,
    });
    const [selectAllStatus, setSelectAllStatus] = useState({
        visible: false,
        checked: false
    });
    const inputRef = useRef(null);
    const contactsRef = useRef(null);
    // Search
    const [expandedKeys, setExpandedKeys] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    // Observar mudanças na arvore de segmentos??
    useEffect(() => {
        setContactsTree(state.descriptionObjects.contacts.tree);
    }, [state.descriptionObjects.contacts.tree])

    //Search
    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
    };

    useEffect(() => {
        const { checkeds } = checkStatus;
        if (deletingContacts) {
            setCheckStatus({ visible: true, checkeds: [], checkStrictly: false });
            setSelectAllStatus({ visible: true, checked: false });
        }
        if (!deletingContacts && checkeds !== null) {
            if (checkeds.length > 0) {
                Swal.fire({
                    title: 'Você tem certeza que deseja remover estes objetos?',
                    text: "Não será possível reverter isto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, apague!',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed)
                        deleteContact(checkeds);
                    setSelectAllStatus({ visible: false, checked: false });
                    setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
                })
            } else {
                setSelectAllStatus({ visible: false, checked: false });
                setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
            }
        }
    }, [deletingContacts])

    useEffect(() => {
        console.log("updatedSegment", state.descriptionObjects.segment.updated);
        if (state.descriptionObjects.segment.updated === 0) return;

        const newContactsTree = state.descriptionObjects.contacts.tree.map(node => {
            const children = node.children?.filter(child =>
                findNodeByKey(state.descriptionObjects.segment.tree, child.original_key) !== null
            );
            return (children.length > 0) ? { ...node, children: children } : null;
        }).filter(e => e);

        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            contacts: {
                ...state.descriptionObjects.contacts,
                tree: newContactsTree,
                updated: state.descriptionObjects.segment.updated + 1
            }
        }));
    }, [state.descriptionObjects.segment.updated])

    function traverseTree(data) {
        const stack = [...data];
        const result = [];

        while (stack.length > 0) {
            const node = stack.pop();
            if (node.children) {
                result.push(node.key);
            }
        }

        return result;
    }

    function findNodesWithSubstring(tree, substring) {
        const result = [];

        function traverse(node) {
            if (node === null || typeof node !== 'object') {
                return;
            }

            // Checks if the substring is present in the node key
            if (substring != "" && node.hasOwnProperty('title') && typeof node.title === 'string') {
                let key = node.title.toLowerCase();
                if (key.includes(substring.toLowerCase())) {
                    result.push(node.key);
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverse(child);
                });
            }
        }

        tree.forEach(node => traverse(node));

        return result;
    }

    function updateTitles(treeInput, nodesWithSubstring, substring) {
        var tree = treeInput;
        function traverseAndUpdate(node) {
            if (node === null || typeof node !== 'object') {
                return;
            }

            // If the node's key is in the nodesWithSubstring list, update the title
            if (node.hasOwnProperty('key') && typeof node.key === 'string') {
                let title = node.title;
                let key = node.key;
                if (substring != "" && nodesWithSubstring.includes(key)) {
                    let index = title.toLowerCase().indexOf(substring.toLowerCase());

                    let before = title.substring(0, index);
                    let match = title.substring(index, index + substring.length);
                    let after = title.substring(index + substring.length);

                    node.before = before;
                    node.match = match;
                    node.after = after;
                }
                else {
                    node.match = "";
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverseAndUpdate(child);
                });
            }
        }

        tree.forEach(node => traverseAndUpdate(node));
    }

    const onChange = (e) => {
        const { value } = e.target;
        let newExpandedKeys = findNodesWithSubstring(contactsTree, value);
        // console.log(newExpandedKeys)
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
        updateTitles(contactsTree, newExpandedKeys, value);
        onExpand(newExpandedKeys);
        if (newExpandedKeys.length >= 1)
            contactsRef.current.scrollTo({ key: newExpandedKeys[0], align: "top" });
    };

    useEffect(() => {
        if (!openSearchBar)
            updateTitles(contactsTree, [], "key0");
    }, [openSearchBar])

    const onClickOutside = () => {
        setContextMenu({ ...contextMenu, visible: false, node: null });
        document.removeEventListener('click', onClickOutside);
    }

    useEffect(() => {
        if (contextMenu.visible)
            document.addEventListener('click', onClickOutside);
    }, [contextMenu])

    useEffect(() => {
        const contactsTree = state.descriptionObjects.contacts.tree;
        const { key, value, newKey, update } = edit;

        if (newKey) {
            inputRef.current.focus();
        }

        if (update) {
            dispatch(setDescriptionObjectsAction({
                ...state.descriptionObjects,
                contacts: {
                    ...state.descriptionObjects.contacts,
                    tree: updateObj(key, contactsTree, 'title', value),
                    updated: state.descriptionObjects.contacts.updated + 1
                }
            }));
            setEdit({ key: null, value: '', newKey: false, update: false });
        }
    }, [edit]);

    const deleteContact = (ids) => {
        const newContactsTree = state.descriptionObjects.contacts.tree.filter(e => !ids.includes(e.key));
        const newSelectedContacts = state.descriptionObjects.contacts.selectedContact.filter(key =>
            findNodeByKey(newContactsTree, key) !== null
        );
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            contacts: {
                ...state.descriptionObjects.contacts,
                tree: newContactsTree,
                selectedContact: newSelectedContacts,
                updated: state.descriptionObjects.contacts.updated + 1
            }
        }));
    }

    const confirmDeleteContact = (keys) => {
        Swal.fire({
            title: "Você tem certeza que deseja remover este contato?",
            text: "Não será possível reverter isto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apague!',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteContact(keys);
            }
        });
    };

    const titleRender = (nodeData) => {
        const { originalTitle, key, icon, contactType } = nodeData;

        let tempTitle = originalTitle;

        if (nodeData.hasOwnProperty('before') && nodeData.hasOwnProperty('after') && nodeData.hasOwnProperty('match') && nodeData.match != "") {
            tempTitle = <span className='search-result-tree'>{nodeData.before}<span className="site-tree-search-value search-result-tree">{nodeData.match}</span>{nodeData.after}</span>;
        }
        else {
            tempTitle = nodeData.title;
        }

        let title = tempTitle;

        let hasChildren = "children" in nodeData;

        let info = contactType;

        const getContactTypeIcon = (type) => {
            const icons = {
                'Abrupto': <img src={Sharp} alt="Sharp" style={{ width: '20px', height: '20px' }} />,
                'Críptico': <img src={Cryptic} alt="Cryptic" style={{ width: '20px', height: '20px' }} />,
                'De falha': <img src={Faulted} alt="Faulted" style={{ width: '20px', height: '20px' }} />,
                'Erosional': <img src={Erosive} alt="Erosive" style={{ width: '20px', height: '20px' }} />,
                'Gradacional': <img src={Gradational} alt="Gradational" style={{ width: '20px', height: '20px' }} />,
                'Linha de seixos': <img src={LagDeposit} alt="Lag Deposit" style={{ width: '20px', height: '20px' }} />,
                'Indefinido': <img src={Undefined} alt="Undefined" style={{ width: '20px', height: '20px' }} />,
                'Amalgamado': null,
                'Concordante': null
            };

            return icons[type] || null;
        };

        const items = [
            getItem('Mudar Tipo', 'change_type', null, [
                getItem(<>{getContactTypeIcon('Abrupto')} Abrupto</>, 'Abrupto', null),
                getItem(<>{getContactTypeIcon('Críptico')} Críptico</>, 'Críptico', null),
                getItem(<>{getContactTypeIcon('De falha')} De falha</>, 'De falha', null),
                getItem(<>{getContactTypeIcon('Erosional')} Erosional</>, 'Erosional', null),
                getItem(<>{getContactTypeIcon('Gradacional')} Gradacional</>, 'Gradacional', null),
                getItem(<>{getContactTypeIcon('Linha de seixos')} Linha de seixos</>, 'Linha de seixos', null),
                getItem(<>{getContactTypeIcon('Indefinido')} Indefinido</>, 'Indefinido', null),
                getItem(<>{getContactTypeIcon('Amalgamado')} Amalgamado</>, 'Amalgamado', null),
                getItem(<>{getContactTypeIcon('Concordante')} Concordante</>, 'Concordante', null)
            ]),
            getItem('Renomear', 'rename', <EditOutlined style={displayInline} />),
            getItem('Remover', 'delete', <DeleteOutlined style={displayInline} />)
        ];

        const handleMenuClick = ({ key, keyPath, domEvent }) => {
            const actionMap = {
                'delete': () => confirmDeleteContact([nodeData.key]),
                'rename': () => setEdit({ ...edit, key: nodeData.key, value: nodeData.title, newKey: true }),
                'change_type': () => {
                    dispatch(setDescriptionObjectsAction({
                        ...state.descriptionObjects,
                        contacts: {
                            ...state.descriptionObjects.contacts,
                            tree: updateObj(nodeData.key, contactsTree, 'contactType', keyPath[1]),
                            updated: state.descriptionObjects.contacts.updated + 1
                        }
                    }));
                    setEdit({ key: null, value: '', newKey: false, update: false, contactType: null });
                }
            };

            domEvent.stopPropagation();
            const action = keyPath.reverse()[0];
            const actionFunction = actionMap[action];
            if (actionFunction) {
                actionFunction();
            }
            setContextMenu({ ...contextMenu, visible: false, node: null });
            document.removeEventListener('click', onClickOutside);
        }

        const menuProps = {
            items,
            onClick: handleMenuClick,
        };

        const handleKeyDown = (event) => {
            const ENTER = 13;
            const ESCAPE = 27;

            if (event.keyCode === ENTER)
                setEdit({ ...edit, update: true })
            else if (event.keyCode === ESCAPE)
                setEdit({ ...edit, key: null })
        }

        const handleMouseEnter = () => {
            setIsHovered(key);
        };

        const handleMouseLeave = () => {
            setIsHovered(null);
        };

        const handleDoubleClick = () => {
            if (hasChildren)
                setEdit({ ...edit, key: key, value: title, newKey: true })
        }

        const openContextMenu = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setContextMenu({ ...contextMenu, visible: true, node: key });
        }

        return (
            <Row
                gutter={6}
                wrap={false}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onContextMenu={openContextMenu}
                onDoubleClick={handleDoubleClick}
            >
                <Col flex="none">
                    {icon}
                    <span style={{ marginLeft: '8px' }}></span>
                </Col>
                {
                    edit.key === key
                        ?
                        <Col flex="auto">
                            <Input
                                ref={inputRef}
                                size='small'
                                value={edit.value}
                                onChange={(e) => setEdit({ ...edit, value: e.target.value })}
                                onKeyDown={handleKeyDown}
                                autoFocus
                            />
                        </Col>
                        :
                        <Col
                            flex="auto"
                            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {title}
                        </Col>
                }

                {
                    hasChildren && (isHovered == key || edit.key === key || (contextMenu.visible && contextMenu.node === key))
                        ?
                        <Col flex="none" style={{ flexShrink: "0" }}>
                            <Dropdown
                                menu={menuProps}
                                open={contextMenu.visible && contextMenu.node === key}
                                onClick={openContextMenu}
                                autoFocus
                            >
                                <MoreOutlined
                                    aria-label="add product name to cart"
                                    style={{
                                        display: "inline-flex",
                                        color: contextMenu.node === key ? "#0000EE" : "black",
                                        fontSize: "15px"
                                    }}
                                />
                            </Dropdown>
                        </Col>
                        :
                        <Col flex="none" style={{ flexShrink: "0" }}>
                            <span style={{ fontSize: "10px" }}>
                                {info}
                            </span>
                        </Col>
                }
            </Row>
        )
    }

    const onSelect = (keys, event) => {
        const { node: { key }, nativeEvent: { ctrlKey } } = event;
        const newSelecteds = updateSelectedKeys(state.descriptionObjects.contacts.selectedContact, key, ctrlKey);
        dispatch(setSelectedContact(newSelecteds))
    }

    const onChangeSelectAll = (e) => {
        const checked = e.target.checked;
        const allKeys = traverseTree(contactsTree);
        setSelectAllStatus({ ...selectAllStatus, checked: checked });
        setCheckStatus({ ...checkStatus, checkeds: checked ? allKeys : [] });
    };

    const onCheck = (checkedKeys, info) => {
        // console.log(checkedKeys, info);
        if (!selectAllStatus.checked)
            setCheckStatus({ ...checkStatus, checkeds: checkedKeys });
    };

    return (
        <>
            {openSearchBar &&
                <Search
                    style={{
                        backgroundColor: 'white',
                        padding: 8,
                        border: 'none'
                    }}
                    placeholder='Pesquisar'
                    onChange={debounce(onChange, 300)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
                />
            }

            {selectAllStatus.visible &&
                <div style={{ paddingLeft: 36, lineHeight: 1.5715, paddingBottom: 4 }}>
                    <Checkbox checked={selectAllStatus.checked} onChange={onChangeSelectAll}>
                        <span style={{ paddingLeft: 4 }}>
                            Selecionar tudo
                        </span>
                    </Checkbox>
                </div>
            }

            <Tree
                ref={contactsRef}
                blockNode
                treeData={contactsTree}
                showLine={{ showLeafIcon: false }}
                titleRender={titleRender}
                onSelect={onSelect}
                selectedKeys={state.descriptionObjects.contacts.selectedContact}
                multiple
                height={height - (openSearchBar ? 48 : 0) - (selectAllStatus.visible ? 26 : 0)}

                //Check
                onCheck={onCheck}
                checkable={checkStatus.visible}
                checkStrictly={checkStatus.checkStrictly}
                checkedKeys={checkStatus.checkeds}

                //Search
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                style={{ paddingLeft: 12 }}

            />
        </>
    );
}

export default ContactsForm;
import { Tree, Dropdown, Row, Col, Input, Typography, Checkbox } from 'antd';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../../../../Store';
import { updateObjectStructure, setSelectedObject, setSelectingSegment, setCheckedsObjectsSegments, setSelectedSegment, deleteDescriptionObject, setObjectProperties, updateSegments, addDescriptionObject, updateContacts, setDescriptionObjectsAction } from '../../../../../Store/actions';
import { EditOutlined, DeleteOutlined, CopyOutlined, CheckOutlined, PlusOutlined, MoreOutlined, ConsoleSqlOutlined } from '@ant-design/icons';
import { BiRuler } from 'react-icons/bi';
import { ObjectTypes, ScaleTypes } from "../../types";
import "./index.css"
import { debounce } from 'lodash';
import Swal from 'sweetalert2';
import AddObjectModal from '../addObjectModal';
import { updateSelectedKeys } from '../../../../../../utils';
import { useAxiosGet } from '../../../../../../hooks/useAxios';

const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;

const { Search } = Input;

function getItem(label, key, icon, children, theme) {
    return {
        key,
        icon,
        children,
        label,
        theme,
    };
}

function updateObj(key, data, propertyName, propertyValue, propertyKey = 'key') {
    const updateData = (data, key) => data.map(e => {
        if (e.children)
            e.children = updateData(e.children, key);
        if (e[propertyKey] === key) {
            return {
                ...e,
                [propertyName]: propertyValue
            };
        }
        return e;
    })

    return updateData(data, key);
}


function removeObjects(data, keysToRemove) {
    return data
        .map(node => ({
            ...node,
            children: removeObjects(node.children, keysToRemove)
        }))
        .filter(node => !keysToRemove.includes(node.key));
}

function removeSegments(data, keysToRemove) {
    return data.filter(obj =>
        obj.children.every(child => !keysToRemove.includes(child.original_key))
    );
}

function traverseTree(data) {
    const stack = data.slice();
    const result = [];

    while (stack.length > 0) {
        const current = stack.pop();
        if (current.children && current.children.length > 0)
            stack.push(...current.children);
        result.push(current.key);
    }

    return result;
}


function isKeyInSubtree(node, key) {
    if (!node || !node.children) return false;
    if (node.key === key) return true;
    for (const child of node.children) {
        if (node.key === key || isKeyInSubtree(child, key)) {
            return true;
        }
    }
    return false;
};

const displayInline = { display: "inline-flex" };

const InvisibleIcon = () => (
    <EditOutlined style={{ color: 'transparent' }} aria-hidden="true" />
);

const getGeometryTypeOptions = (architecturalElementData) => {
    if (!architecturalElementData || !architecturalElementData.geologicalObjects
        || !architecturalElementData.geologicalObjects.architecturalElement
        || !architecturalElementData.geologicalObjects.architecturalElement.typeGeometry) {
        return [];
    }

    const geometryTypeData = architecturalElementData.geologicalObjects.architecturalElement.typeGeometry.types;
    return geometryTypeData.map((node) => ({
        title: node.labels.pt[0],
        value: node.iri
    }));
};


const ObjectTree = (props) => {
    const { viewOnly, height, openSearchBar, deletingObjects } = props;
    const globalState = useContext(store);
    const { dispatch, state } = globalState;
    const [gData, setGData] = useState(state.descriptionObjects.objects);
    // const [selectedKeys, setSelectedKeys] = useState([]);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        node: null,
    });
    const [edit, setEdit] = useState({
        key: null,
        value: '',
        newKey: false,
        update: false
    });
    const [isHovered, setIsHovered] = useState(null);
    const [open, setOpen] = useState(false);
    const parentRef = useRef(null);
    const inputRef = useRef(null);
    const treeRef = useRef(null);
    const mergeRef = useRef(null);

    const architecturalElementTypes = state.ontologyInformation.architecturalElementTypes;
    const faciesAssociationTypes = state.ontologyInformation.faciesAssociationTypes;

    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const { data: architecturalElementData } = useAxiosGet(descriptionInfoURL, sessionStorage);
    const geometryTypeOptions = getGeometryTypeOptions(architecturalElementData);

    const onClickOutside = () => {
        setContextMenu({ ...contextMenu, visible: false, node: null });
        document.removeEventListener('click', onClickOutside);
    }
    //Search
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [checkStatus, setCheckStatus] = useState({
        visible: false,
        checkStrictly: false,
        checkeds: null,
    });
    const [selectAllStatus, setSelectAllStatus] = useState({
        visible: false,
        checked: false
    });
    const [onDrag, setOnDrag] = useState(false);

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    useEffect(() => {
        let tree = state.descriptionObjects.objects;
        let newExpandedKeys = findNodesWithSubstring(tree, "");
        updateTitles(tree, newExpandedKeys, "");
        setGData(tree);
    }, [state.descriptionObjects.objects]);

    useEffect(() => {
        if (contextMenu.visible) {
            document.addEventListener('click', onClickOutside);
        }
    }, [contextMenu])

    useEffect(() => {
        if (!openSearchBar)
            updateTitles(gData, [], "key0");
    }, [openSearchBar])

    useEffect(() => {
        const objects = state.descriptionObjects.objects;
        const { key, value, newKey, update } = edit;

        if (newKey) {
            inputRef.current.focus();
            setEdit({ ...edit, newKey: false });
        }

        if (update) {
            const newObjects = updateObj(key, objects, 'title', value);
            dispatch(setDescriptionObjectsAction({
                ...state.descriptionObjects,
                objects: newObjects,
                updatedObjects: state.descriptionObjects.updatedObjects + 1,
            }));
            setEdit({ ...edit, key: null, update: false });
        }
    }, [edit]);

    useEffect(() => {
        const { checkeds } = checkStatus;
        if (deletingObjects) {
            setCheckStatus({ visible: true, checkeds: [], checkStrictly: false });
            setSelectAllStatus({ visible: true, checked: false });
        } else if (checkeds !== null) {
            if (checkeds.length > 0) {
                Swal.fire({
                    title: 'Você tem certeza que deseja remover estes objetos?',
                    text: "Não será possível reverter isto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, apague!',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed)
                        deleteObjects(checkeds);
                    setSelectAllStatus({ visible: false, checked: false });
                    setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
                })
            } else {
                setSelectAllStatus({ visible: false, checked: false });
                setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
            }
        }
    }, [deletingObjects]);

    const deleteObjects = (keys) => {
        const newObjects = removeObjects(gData, keys);
        const newSelectedObject = state.descriptionObjects.selectedObject.filter(key =>
            findNodeByKey(newObjects, key) !== null
        );
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            objects: newObjects,
            selectedObject: newSelectedObject,
            updatedObjects: state.descriptionObjects.updatedObjects + 1,
        }));
    }

    const confirmDeleteObjects = (keys) => {
        const nodeToDelete = findNodeByKey(gData, keys[0]);

        // Check if the node has children
        const hasChildren = nodeToDelete.children && nodeToDelete.children.length > 0;

        let confirmationMessage = 'Você tem certeza que deseja remover este objeto?';
        if (hasChildren) {
            confirmationMessage = 'Este objeto possui objetos filhos. A sua deleção implicará na deleção dos filhos. Você confirma a deleção?';
        }

        Swal.fire({
            title: confirmationMessage,
            text: "Não será possível reverter isto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, apague!',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteObjects(keys);
            }
        });
    };

    const onSelect = (keys, event) => {
        const { node: { key }, nativeEvent: { ctrlKey } } = event;
        const selectedObject = state.descriptionObjects.selectedObject;
        const newSelecteds = updateSelectedKeys(selectedObject, key, ctrlKey);
        dispatch(setSelectedObject(newSelecteds));
    };

    const findNodeByKey = (tree, keyToFind) => {
        // Helper function to recursively find a node by key
        function findNode(node, targetKey) {
            if (node.key === targetKey) {
                return node; // Found the target node
            }
            if (node.children && node.children.length > 0) {
                for (const childNode of node.children) {
                    const foundNode = findNode(childNode, targetKey);
                    if (foundNode) {
                        return foundNode; // The target node is somewhere in this subtree
                    }
                }
            }
            return null; // The target node was not found in this subtree
        }

        // Search for the node with the given key in the tree
        for (const rootNode of tree) {
            const foundNode = findNode(rootNode, keyToFind);
            if (foundNode) {
                return foundNode; // Return the found node
            }
        }

        // The node with the given key was not found in the tree
        return null;
    }

    const allowDrop = ({ dragNode, dropNode, dropPosition }) => {
        const dragObjects = state.descriptionObjects.selectedObject.map(key => findNodeByKey(gData, key));
        return !dragObjects.some(node => isKeyInSubtree(node, dropNode.key));
    };

    const onDrop = (info) => {
        const dropKey = info.node.key;
        const dragKeys = state.descriptionObjects.selectedObject;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const dragObjects = dragKeys.map(key => findNodeByKey(gData, key));

        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key === key)
                    return callback(item, index, arr);
                if (item.children)
                    return loop(item.children, key, callback);
            });
        };

        const data = [...gData];
        dragKeys.forEach((dragKey) => {
            loop(data, dragKey, (item, index, arr) => {
                arr.splice(index, 1);
            });
        });

        const shouldAppendAsChild = (info.node.props.children || []).length > 0 &&
            info.node.props.expanded &&
            dropPosition === 1;

        if (!info.dropToGap || shouldAppendAsChild) {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                item.children.unshift(...dragObjects);
            });
        } else {
            let ar = [];
            let i;
            loop(data, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });

            if (dropPosition === -1)
                ar.splice(i, 0, ...dragObjects);
            else
                ar.splice(i + 1, 0, ...dragObjects);
        }

        dispatch(updateObjectStructure(data));
        setOnDrag(false);
    };


    const onCheck = (checkedKeys, info) => {
        if (!selectAllStatus.checked)
            setCheckStatus({ ...checkStatus, checkeds: checkedKeys });
    };

    const setObjectPropertie = (nodeKey, propertieKey, propertieValue) => {
        const updateData = (data) => data.map(e => {
            if (e.children)
                e.children = updateData(e.children);
            if (e.key === nodeKey) {
                const newNode = e;
                if (propertieKey === 'unitType') newNode.unitType = propertieValue;
                if (propertieKey === 'architecturalElementType') newNode.architecturalElementType = propertieValue;
                if (propertieKey === 'faciesAssociationType') newNode.faciesAssociationType = propertieValue;
                if (propertieKey === 'scaleType') newNode.properties.scaleType = propertieValue;
                if (propertieKey === 'geometryType') newNode.properties.typeGeometry = propertieValue;
                return newNode;
            }
            return e;
        })

        const objects = state.descriptionObjects.objects;
        const newObjects = updateData(objects);
        dispatch(setObjectProperties(newObjects));
    }


    //Search
    const onExpand = (newExpandedKeys, info) => {
        const { node, expanded } = info;

        if (!expanded) {
            const parentKey = node.key;
            const filteredKeys = newExpandedKeys.filter(key => !key.startsWith(parentKey));
            setExpandedKeys(filteredKeys);
        } else {
            setExpandedKeys(newExpandedKeys);
        }

        setAutoExpandParent(false);
    };

    function findNodesWithSubstring(tree, substring) {
        const result = [];

        function traverse(node) {
            if (node === null || typeof node !== 'object') {
                return;
            }

            // Checks if the substring is present in the node key
            if (substring != "" && node.hasOwnProperty('title') && typeof node.title === 'string') {
                let key = node.title.toLowerCase();
                if (key.includes(substring.toLowerCase())) {
                    result.push(node.key);
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverse(child);
                });
            }
        }

        tree.forEach(node => traverse(node));

        return result;
    }

    function updateTitles(treeInput, nodesWithSubstring, substring) {
        var tree = treeInput;

        function traverseAndUpdate(node) {
            if (node === null || typeof node !== 'object') {
                return;
            }

            // If the node's key is in the nodesWithSubstring list, update the title
            if (node.hasOwnProperty('key') && typeof node.key === 'string') {
                let title = node.title;
                let key = node.key;
                if (substring != "" && nodesWithSubstring.includes(key)) {
                    let index = title.toLowerCase().indexOf(substring.toLowerCase());

                    let before = title.substring(0, index);
                    let match = title.substring(index, index + substring.length);
                    let after = title.substring(index + substring.length);

                    node.before = before;
                    node.match = match;
                    node.after = after;
                }
                else {
                    node.match = "";
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverseAndUpdate(child);
                });
            }
        }

        tree.forEach(node => traverseAndUpdate(node));
    }

    const onChange = (e) => {
        const { value } = e.target;
        let newExpandedKeys = findNodesWithSubstring(gData, value);
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
        updateTitles(gData, newExpandedKeys, value);
        onExpand(newExpandedKeys);
        if (newExpandedKeys.length >= 1)
            treeRef.current.scrollTo({ key: newExpandedKeys[0], align: "top" });
    };

    const duplicateObj = (nodeData) => {
        const objects = state.descriptionObjects.objects;
        const descriptionNextId = state.descriptionObjects.nextId;
        const { title, key, geologicalObjectType, architecturalElementType, faciesAssociationType, unitType, icon } = nodeData;
        const { polygonId, ...rest } = nodeData.properties;
        const updateData = (data, key) => data.map(e => {
            if (e.children) {
                e.children = updateData(e.children, key);
                const index = e.children.findIndex((obj) => obj.key === key);
                if (index > -1)
                    e.children.splice(index + 1, 0, copy);
            }
            return e;
        })

        let nextId = (geologicalObjectType in descriptionNextId)
            ? nextId = descriptionNextId[geologicalObjectType] + 1
            : nextId = 1;

        let updatedNextId = {
            ...descriptionNextId,
            [geologicalObjectType]: nextId
        }

        let copy = {
            title: "Cópia de " + title,
            key: geologicalObjectType + nextId,
            idNumber: nextId,
            geologicalObjectType: geologicalObjectType,
            properties: { ...rest },
            icon: icon,
        }

        if (architecturalElementType)
            copy.architecturalElementType = architecturalElementType;
        if (unitType)
            copy.unitType = unitType;
        if (faciesAssociationType)
            copy.faciesAssociationType = faciesAssociationType

        let newArray = updateData(objects, key);
        let index = newArray.findIndex((obj) => obj.key === key);
        if (index > -1)
            newArray.splice(index + 1, 0, copy);

        let newObjects = {
            objects: newArray,
            nextId: updatedNextId,
        }
        dispatch(addDescriptionObject(newObjects));
    }

    const titleRender = (nodeData) => {
        const { originalTitle, key, icon, geologicalObjectType, architecturalElementType: archElementType, faciesAssociationType: faciesType } = nodeData;
        const { scaleType } = nodeData.properties;
        const backgroundColor = (contextMenu.node === key && state.descriptionObjects.selectedObject[0] !== key) ? "#f5f5f5" : "";
        const geometryType = nodeData.properties.typeGeometry;

        let tempTitle = originalTitle;

        if (nodeData.hasOwnProperty('before') && nodeData.hasOwnProperty('after') && nodeData.hasOwnProperty('match') && nodeData.match != "") {
            tempTitle = <span className='search-result-tree'>{nodeData.before}<span className="site-tree-search-value search-result-tree">{nodeData.match}</span>{nodeData.after}</span>;
        }
        else {
            tempTitle = nodeData.title;
        }

        let title = tempTitle

        const items = [
            ...(geologicalObjectType === ARCH_ELEMENT || geologicalObjectType === UNIT
                ? [
                    getItem(
                        'Tipo do elemento arquitetural',
                        'architecturalElementType',
                        <InvisibleIcon />,
                        Object.values(architecturalElementTypes).map((value) =>
                            getItem(
                                value.labels["pt-br"] || value.labels["pt"],
                                value.labels["pt-br"] || value.labels["pt"],
                                archElementType === value
                                    ? <CheckOutlined style={displayInline} />
                                    : <InvisibleIcon style={displayInline} />
                            )
                        )
                    ),
                ]
                : []
            ),
            ...(geologicalObjectType === FACIES_ASSOCIATION
                ? [
                    getItem(
                        'Tipo de associação de fácies',
                        'faciesAssociationType',
                        <InvisibleIcon />,
                        Object.values(faciesAssociationTypes).map((value) =>
                            getItem(
                                value.labels["pt-br"] || value.labels["pt"],
                                value.labels["pt-br"] || value.labels["pt"],
                                faciesType === value
                                    ? <CheckOutlined style={displayInline} />
                                    : <InvisibleIcon style={displayInline} />
                            )
                        )
                    ),
                ]
                : []
            ),
            ...(geologicalObjectType === ARCH_ELEMENT || geologicalObjectType === UNIT
                ? [
                    getItem(
                        'Escala',
                        'scaleType',
                        <BiRuler style={{ display: "inline-flex" }} />,
                        Object.values(ScaleTypes).map((value) =>
                            getItem(
                                value,
                                value,
                                scaleType === value
                                    ? <CheckOutlined style={displayInline} />
                                    : <InvisibleIcon />
                            )
                        )
                    ),
                ]
                : []
            ),
            ...(geologicalObjectType === ARCH_ELEMENT || geologicalObjectType === UNIT
                ? [
                    getItem(
                        'Tipo de Geometria',
                        'geometryType',
                        <InvisibleIcon />,
                        geometryTypeOptions.map((option) =>
                            getItem(
                                option.title,
                                option.value,
                                option.value === geometryType
                                    ? <CheckOutlined style={displayInline} />
                                    : <InvisibleIcon />
                            )
                        )
                    ),
                ]
                : []
            ),
            getItem(
                'Adicionar objeto',
                'addChild',
                <PlusOutlined style={{ display: "inline-flex" }} />,
            ),
            getItem('Renomear', 'rename', <EditOutlined style={displayInline} />),
            getItem('Duplicar', 'duplicate', <CopyOutlined style={displayInline} />),
            getItem('Remover', 'delete', <DeleteOutlined style={displayInline} />)
        ];

        const handleMenuClick = ({ key, keyPath, domEvent }) => {
            const actionMap = {
                'delete': () => confirmDeleteObjects([nodeData.key]),
                'scaleType': () => setObjectPropertie(nodeData.key, 'scaleType', key),
                'architecturalElementType': () => setObjectPropertie(nodeData.key, 'architecturalElementType', key),
                'faciesAssociationType': () => setObjectPropertie(nodeData.key, 'faciesAssociationType', key),
                'geometryType': () => setObjectPropertie(nodeData.key, 'geometryType', key),
                'unitType': () => setObjectPropertie(nodeData.key, 'unitType', key),
                'rename': () => setEdit({ ...edit, key: nodeData.key, value: nodeData.title, newKey: true }),
                'addChild': () => { parentRef.current = nodeData.key; setOpen(true); },
                'duplicate': () => duplicateObj(nodeData)
            };

            domEvent.stopPropagation();
            const action = keyPath.reverse()[0];
            const actionFunction = actionMap[action];
            if (actionFunction) {
                actionFunction();
            }
            setContextMenu({ ...contextMenu, visible: false, node: null });
            document.removeEventListener('click', onClickOutside);
        }

        const menuProps = {
            items,
            onClick: handleMenuClick,
        };

        const handleKeyDown = (event) => {
            const ENTER = 13;
            const ESCAPE = 27;

            if (event.keyCode === ENTER)
                setEdit({ ...edit, update: true })
            else if (event.keyCode === ESCAPE)
                setEdit({ ...edit, key: null })
        }

        const handleMouseEnter = () => {
            setIsHovered(key);
        };

        const handleMouseLeave = () => {
            setIsHovered(null);
        };

        const openContextMenu = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setContextMenu({ ...contextMenu, visible: true, node: key });
        }

        let info;

        if (geologicalObjectType === ARCH_ELEMENT || geologicalObjectType === UNIT) {
            info = archElementType ?? "Não definido";
        }

        if (geologicalObjectType === FACIES_ASSOCIATION) {
            info = faciesType ?? "Não definido";
        }

        if (scaleType) {
            info += ' · ' + scaleType;
        }

        return (
            <Row
                gutter={6}
                wrap={false}
                style={{ backgroundColor: backgroundColor, lineHeight: 1.5715, paddingRight: 6 }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onContextMenu={openContextMenu}
            >
                <Col flex="none">
                    {icon}
                </Col>
                {
                    edit.key === key
                        ?
                        <Col flex="auto">
                            <Input
                                ref={inputRef}
                                size='small'
                                value={edit.value}
                                onChange={(e) => { setEdit({ ...edit, value: e.target.value }) }}
                                onKeyDown={handleKeyDown}
                            />
                        </Col>
                        :
                        <Col
                            flex="auto"
                            onDoubleClick={() => setEdit({ ...edit, key: key, value: title, newKey: true })}
                            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {title}
                        </Col>
                }
                {
                    isHovered === key || edit.key === key || (contextMenu.visible && contextMenu.node === key) ?
                        <Col flex="none" style={{ flexShrink: "0" }}>
                            <Dropdown
                                menu={menuProps}
                                open={contextMenu.visible && contextMenu.node === key}
                                onClick={openContextMenu}
                            >
                                <MoreOutlined
                                    aria-label="add product name to cart"
                                    style={{
                                        display: "inline-flex",
                                        color: contextMenu.node === key ? "#0000EE" : "black",
                                        fontSize: "15px"
                                    }}
                                />
                            </Dropdown>
                        </Col>
                        :
                        <Col flex="none" style={{ flexShrink: "0" }}>
                            <span style={{ fontSize: "10px" }}>
                                {info}
                            </span>
                        </Col>
                }
            </Row>
        )
    }

    const onChangeSelectAll = (e) => {
        const checked = e.target.checked;
        setSelectAllStatus({ ...selectAllStatus, checked: checked });
        if (checked)
            setCheckStatus({ ...checkStatus, checkeds: traverseTree(gData) });
    }

    const isNodeDraggable = (node) => {
        return onDrag || state.descriptionObjects.selectedObject.includes(node.key);
    };

    return (
        <>
            <span>
                {openSearchBar &&
                    <Search
                        style={{
                            backgroundColor: 'white',
                            padding: 8,
                            border: 'none'
                        }}
                        placeholder='Pesquisar'
                        onChange={debounce(onChange, 300)}
                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}
                    />
                }

                {selectAllStatus.visible &&
                    <div style={{ paddingLeft: 60, lineHeight: 1.5715, paddingBottom: 4 }}>
                        <Checkbox checked={selectAllStatus.checked} onChange={onChangeSelectAll}>
                            <span style={{ paddingLeft: 4 }}>
                                Selecionar tudo
                            </span>
                        </Checkbox>
                    </div>
                }

                <Tree
                    ref={treeRef}
                    treeData={gData}
                    className="draggable-tree"
                    draggable={{ nodeDraggable: isNodeDraggable }}
                    onDragStart={() => setOnDrag(true)}
                    onDragEnd={() => setOnDrag(false)}
                    blockNode
                    onDrop={onDrop}
                    allowDrop={allowDrop}
                    onCheck={onCheck}
                    onSelect={onSelect}
                    multiple
                    selectedKeys={state.descriptionObjects.selectedObject}
                    showLine={{ showLeafIcon: false }}
                    titleRender={titleRender}
                    height={height - (openSearchBar ? 48 : 0) - (selectAllStatus.visible ? 26 : 0)}

                    // Check
                    checkable={checkStatus.visible}
                    checkStrictly={checkStatus.checkStrictly}
                    checkedKeys={checkStatus.checkeds}

                    // Search
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    defaultExpandParent={false}

                    style={{ paddingLeft: 12 }}
                />
            </span>

            {open && <AddObjectModal setOpen={setOpen} parentRef={parentRef} mergeRef={mergeRef} />}
        </>
    );
};

export default ObjectTree;

// AddContactModal.js
import React, { useState } from 'react';
import { Modal, Button, Input, Form, Select } from 'antd';
import FloatLabel from "../../../../FloatLabel";
import { ContactTypes } from "../types";
import Sharp from "../../../../../assets/sharp.svg"
import Cryptic from "../../../../../assets/cryptic.svg"
import Faulted from "../../../../../assets/faulted.svg"
import Erosive from "../../../../../assets/erosive.svg"
import Gradational from "../../../../../assets/gradational.svg"
import LagDeposit from "../../../../../assets/lagDeposit.svg"
import Undefined from "../../../../../assets/undefined.png"

const AddContactModal = ({ visible, onCancel, onSubmit }) => {
    const [form] = Form.useForm();

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            console.log("valores:", values);
            console.log("icone:", getIcon(values.contactType));
            onSubmit(values.title, getIcon(values.contactType), values.contactType);
            form.resetFields();
            onCancel();
        });
    };

    function getIcon(type) {
        const icons = {
            'Abrupto': <img src={Sharp} alt="Sharp" style={{ width: '20px', height: '20px' }} />,
            'Críptico': <img src={Cryptic} alt="Cryptic" style={{ width: '20px', height: '20px' }} />,
            'De falha': <img src={Faulted} alt="Faulted" style={{ width: '20px', height: '20px' }} />,
            'Erosional': <img src={Erosive} alt="Erosive" style={{ width: '20px', height: '20px' }} />,
            'Gradacional': <img src={Gradational} alt="Gradational" style={{ width: '20px', height: '20px' }} />,
            'Linha de seixos': <img src={LagDeposit} alt="Lag Deposit" style={{ width: '20px', height: '20px' }} />,
            'Indefinido': <img src={Undefined} alt="Undefined" style={{ width: '20px', height: '20px' }} />,
        };

        return icons[type] || null;
    }

    return (
        <Modal
            title="Adicionar Contato"
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Create
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" name="newContactForm">
                <FloatLabel label="Nome do Contato" name="title" rules={[{ required: true, message: 'Por favor insira um nome para o Contato' }]}>
                    <Input />
                </FloatLabel>

                <FloatLabel label="Tipo do Contato" name="contactType" rules={[{ required: true }]}>
                    <Select
                        style={{ width: '100%' }}
                        options={Object.entries(ContactTypes).map(([key, label]) => {
                            return { value: label, label: <> {getIcon(label)} {label} </> };
                        })}
                    />
                </FloatLabel>
            </Form>
        </Modal>
    );
};

export default AddContactModal;
